import custom_palettes from './custom_palettes';

const custom_tabs = {
  styleOverrides: {
    root: {
      '& .MuiTabs-indicator': {
        backgroundColor: custom_palettes.blue[800]
      }
    }
  }
};

const custom_tab = {
  styleOverrides: {
    root: {
      '&.Mui-selected': {
        color: custom_palettes.blue[800]
      }
    }
  }
};

export { custom_tabs, custom_tab };
