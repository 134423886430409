import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import CustomContainer from 'components/CustomContainer';

import api from 'utils/api';
import OwnersTable from 'components/tables/OwnersTable';
import OwnerDetailsModal from './OwnerDetailsModal';

export default function Owners() {
  const [owner, setOwner]: [any, any] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [action, setAction] = useState('');
  const ref: any = useRef();
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setModalOpen(false);
    setAction('');
  };

  const handleView = async (id: string) => {
    try {
      const owner = await api.get(`/admin/owners/${id}`);
      setOwner({ ...owner.data, id: `${owner.data.id}` });
      setAction('UPDATE');
      setModalOpen(true);
    } catch (err: any) {
      alert(err.response.data.message);
    }
  };

  const handleAction = async (data?: any) => {
    try {
      if (action === 'CREATE') {
        await api.post(`/admin/owners`, data);
      } else {
        await api.patch(`/admin/owners`, data);
      }
      ref.current.refresh();
      setOwner(null);
      handleCloseModal();
      // refresh table
      alert(`${action === 'CREATE' ? 'Saved' : 'Updated'} successfully`);
    } catch (error: any) {
      alert(error.response.data.message);
    }
  };

  return (
    <>
      <CustomContainer>
        <div style={{ height: 32 }} />
        <Button
          type='button'
          variant='primary'
          color='dark'
          data-cy='create-new-owner-button'
          onClick={() => {
            setAction('CREATE');
            setModalOpen(true);
          }}
          style={{
            marginLeft: 20
          }}
        >
          Create new Owner
        </Button>
      </CustomContainer>
      <OwnersTable handleView={handleView} ref={ref} />
      {modalOpen ? (
        <OwnerDetailsModal
          open={modalOpen}
          action={action}
          onClose={handleCloseModal}
          owner={owner}
          handleAction={handleAction}
        />
      ) : (
        ''
      )}
    </>
  );
}
