import { useState, useEffect, Suspense } from 'react';
import Box from '@mui/material/Box';
import DataTable from 'react-data-table-component';
import Cookies from 'universal-cookie';
import { useRecoilValueLoadable, useSetRecoilState } from 'recoil';

import CustomContainer from 'components/CustomContainer';
import { emailState, modalState, userListState } from "pages/Users/state";

interface User {
  id: number;
  name: string;
  email: string;
  roles: any;
  createdAt: string;
  lastLoginAt: string;
}

const customStyles = {
  headRow: {
    style: {
      color: 'white',
      backgroundColor: '#6699CC' //blue
    }
  },
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 'bold',
      border: '1px solid #ddd', // add border to header cells
      justifyContent: 'center'
    }
  },
  rows: {
    style: {
      minHeight: '56px',
      '&:nth-child(even)': {
        backgroundColor: '#FFFFFF' //white
      },
      '&:nth-child(odd)': {
        backgroundColor: '#D0D5E8' //grey
      },
      border: '1px solid #ddd' // add border to rows
    }
  },
  cells: {
    style: {
      fontSize: '14px',
      paddingLeft: '8px',
      paddingRight: '8px',
      width: 'min-content',
      borderRight: '1px solid #ddd', // add right border to cells
      justifyContent: 'center'
    }
  }
};

// =============================================================================
export default function UsersTable() {
  const cookies = new Cookies();
  const [refreshTable, setRefreshTable] = useState(false);

  const setEmail = useSetRecoilState(emailState);
  const setModal = useSetRecoilState(modalState);
  const users = useRecoilValueLoadable<User[]>(userListState)

  const columns: any = [
    {
      cell: (e: User) =>
        <button
          onClick={() => {
            setEmail(e.email)
            setModal(true)
          }}
        >
          Edit
        </button>,
      width: "100px",
    },
    {
      name: 'ID',
      selector: (user: User) => user.id,
      sortable: true,
      width: "100px",
    },
    {
      name: 'Name',
      selector: (user: User) => user.name,
      sortable: true,
      width: "250px",
    },
    {
      name: 'Email',
      selector: (user: User) => user.email,
      sortable: true,
    },
    {
      name: 'Role',
      selector: (user: User) => user.roles.join(','),
      sortable: true,
    },
    {
      name: 'Created',
      selector: (user: User) => {
        const time = parseInt(user.createdAt)
        const formattedDate = new Date(time).toLocaleDateString();
        return formattedDate === "Invalid Date" ? "NA" : formattedDate
      },
      sortable: true,
    },
    {
      name: 'Last login',
      selector: (user: User) => {
        const time = parseInt(user.lastLoginAt)
        const formattedDate = new Date(time).toLocaleDateString();
        return formattedDate === "Invalid Date" ? "NA" : formattedDate
      },
      sortable: true,
    }

  ];

  return (
    <CustomContainer>
      <div style={{ height: 32 }} />
      <Box sx={{ width: '100%' }}>
        <DataTable
          title=''
          columns={[...columns]}
          data={users.state === "hasValue" ? users.contents : []}
          progressPending={users.state === "hasValue" ? false : true}
          pagination
          customStyles={customStyles}
          paginationRowsPerPageOptions={[20, 40, 60, 80, 100]}
        />
      </Box>
    </CustomContainer>
  );
}