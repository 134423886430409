import {
  Dialog,
  MenuItem,
  Button,
  Typography,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { useState, useEffect } from 'react';
import { CONSTANTS } from 'utils/constants';
import { fetchParamsWithPermissions } from 'utils/api';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import useViewportRatio from 'utils/useViewportRatio';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { userState } from './state';
import { If, Then } from 'react-if';

import api from 'utils/api';

interface UserDetailsModalProps {
  open: boolean;
  onClose: () => void;
}

interface User {
  id: number;
  name: string;
  email: string;
  roles: any;
}

const marginSize = 48;

const modalContainerStyle = {
  margin: `${marginSize}px`,
  backgroundColor: 'white'
};

const PARAMETER_TYPES = [
  'WATER_PROPERTIES',
  'WATER_CONTENTS',
  'AIR_PROPERTIES',
  'MONTHLY_CRUISE_PARAMETERS'
];

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && <Box sx={{ p: 3, display: 'flex' }}>{children}</Box>}
    </div>
  );
};

const UserDetailsModal: React.FC<UserDetailsModalProps> = ({
  open,
  onClose
}: {
  open: any;
  onClose: any;
}) => {
  let data = useRecoilValue(userState);
  let [user, setUser] = useState(data);

  const [paramsData, setParamsData]: [any, any] = useState([]);
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const ratio = useViewportRatio(1440);

  const anchorPosition = {
    top: 500,
    left: 400 * ratio
  };

  useEffect(() => {
    const execute = async () => {
      const params: any = await fetchParamsWithPermissions(user.id);

      setParamsData(params);
    };

    execute();
  }, []);

  async function handleSaveUser(paramsAccess?: any) {
    if (!user) {
      alert('Please enter Email and fetch user details');
      return;
    }
    try {
      await api.patch(`/admin/users/${user.id}`, {
        ...user,
        ...(paramsAccess && { parameters: paramsAccess })
      });
      setUser(null);
      alert('Saved successfully');
    } catch (error: any) {
      alert(error.response.data.message);
    }
  }

  const handleRoleChange = (event: any) => {
    const role = event.target.name;
    if (event.target.checked) {
      const roles = new Set([...user.roles, role]);
      setUser({ ...user, roles: Array.from(roles) });
    } else {
      const rolesSet = new Set([...user.roles]);
      rolesSet.delete(role);
      setUser({ ...user, roles: Array.from(rolesSet) });
    }
  };

  const handleParamChange = (event: any) => {
    const id = event.target.name;
    if (event.target.checked) {
      const paramsAccess = [id];

      setParamsData((prevParams: any) => {
        return prevParams.map((param: any) => {
          if (param.permissions.indexOf('VIEW') > -1) {
            paramsAccess.push(param.id);
          }
          return id == param.id ? { ...param, permissions: 'VIEW' } : param;
        });
      });

      setUser({ ...user, parameters: paramsAccess });
    } else {
      const paramsAccess = new Set();
      setParamsData((prevParams: any) => {
        return prevParams.map((param: any) => {
          if (param.permissions.indexOf('VIEW') > -1) {
            paramsAccess.add(param.id);
          }
          return id == param.id ? { ...param, permissions: '' } : param;
        });
      });

      paramsAccess.delete(id);
      setUser({ ...user, parameters: Array.from(paramsAccess) });
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const getTabPanels = () => {
    const locationBasedParams = _.groupBy(paramsData, 'nodeId');
    let locationBasedCategorizedParams: any = {};
    for (const nodeId in locationBasedParams) {
      locationBasedCategorizedParams[nodeId] = _.groupBy(
        locationBasedParams[nodeId],
        'category'
      );
    }

    return Object.values(locationBasedCategorizedParams).map(
      (pData: any, idx) => (
        <TabPanel value={value} index={idx} key={`TabPanel-${idx}`}>
          {PARAMETER_TYPES.map((type: any, index: number) =>
            pData[type] ? (
              <div key={`type-${idx}-${index}`} style={{ marginRight: 10 }}>
                <Typography
                  variant='assistive_text'
                  color='gray.700'
                  style={{
                    ...(type == 'WATER_CONTENTS' && {
                      visibility: 'hidden'
                    })
                  }}
                >
                  {type.replace(/_/g, ' ')}
                </Typography>
                <div style={{ height: 16 }} />
                {pData[type].map((param: any, index: number) => (
                  <div key={`param-${idx}-${index}`}>
                    <FormControlLabel
                      key={param.id}
                      control={
                        <Checkbox
                          color='default'
                          checked={param.permissions.indexOf('VIEW') > -1}
                          onChange={handleParamChange}
                          name={param.id}
                          disabled={param.isFree}
                        />
                      }
                      label={param.parameterDesc}
                    />
                    <If condition={param.rawTableName !== 'cruise_data'}>
                      <Then>
                        <div>
                          <Typography
                            variant='body_regular_strong'
                            color='gray.200'
                          >
                            {param.sensorClass}
                          </Typography>
                        </div>
                      </Then>
                    </If>
                  </div>
                ))}
              </div>
            ) : null
          )}
        </TabPanel>
      )
    );
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        {user ? (
          <div style={modalContainerStyle} className='user-details'>
            <Typography
              variant='h2'
              color='blue.900'
              style={{ marginBottom: 15 }}
            >
              User Details:
            </Typography>
            <div>
              Name: <span>{user.name}</span>
            </div>
            <div>
              Email: <span>{user.email}</span>
            </div>
            <div>
              Roles:
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color='default'
                      checked={user.roles.includes(CONSTANTS.ROLE.ROLE_USER)}
                      onChange={handleRoleChange}
                      name='ROLE_USER'
                      disabled
                    />
                  }
                  label='USER'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color='default'
                      checked={user.roles.includes(
                        CONSTANTS.ROLE.ROLE_COLLABORATOR
                      )}
                      onChange={handleRoleChange}
                      name='ROLE_COLLABORATOR'
                    />
                  }
                  label='COLLABORATOR'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color='default'
                      checked={user.roles.includes(
                        CONSTANTS.ROLE.ROLE_BUOY_MAINTAINER
                      )}
                      onChange={handleRoleChange}
                      name='ROLE_BUOY_MAINTAINER'
                    />
                  }
                  label='BUOY MAINTAINER'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color='default'
                      checked={user.roles.includes(CONSTANTS.ROLE.ROLE_ADMIN)}
                      onChange={handleRoleChange}
                      name='ROLE_ADMIN'
                    />
                  }
                  label='ADMIN'
                />
              </div>
            </div>
            {user.owners.length ? (
              <div>
                Owner: <span>{user.owners}</span>
              </div>
            ) : (
              ''
            )}
            <div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                Quantities:{' '}
                <Button
                  sx={{
                    marginLeft: '30px'
                  }}
                  variant='primary'
                  color='light'
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                  }}
                >
                  Open
                </Button>
              </div>
              <Popover
                open={Boolean(anchorEl)}
                anchorReference={anchorPosition ? 'anchorPosition' : 'anchorEl'}
                anchorPosition={anchorPosition}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right'
                }}
                sx={{
                  '& .MuiPopover-paper': {
                    boxShadow: `0px 8px 10px rgba(0, 0, 0, 0.16)`,
                    borderRadius: '16px',
                    backgroundColor: 'white.50'
                  }
                }}
              >
                <div style={{ padding: '26px 16px 16px 16px' }}>
                  <IconButton
                    size='large'
                    style={{ float: 'right' }}
                    onClick={handlePopoverClose}
                  >
                    <CloseIcon sx={{ color: 'blue.800' }} />
                  </IconButton>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      flexDirection: 'column'
                    }}
                  >
                    <Tabs value={value} onChange={handleChange}>
                      <Tab label='St. John’s Island' />
                      <Tab label='Raffles Lighthouse' />
                      <Tab label='Pulau Ubin' />
                      <Tab label='Hantu' />
                      <Tab label='Kusu' />
                      <Tab label='West Ubin' />
                    </Tabs>
                    {getTabPanels()}
                  </div>
                </div>
              </Popover>
            </div>
            <div
              style={{
                display: 'flex',
                margin: 20
              }}
            >
              <Button
                type='button'
                variant='secondary'
                color='dark'
                onClick={() => {
                  onClose();
                }}
                style={{ marginRight: 15 }}
              >
                Close
              </Button>
              <Button
                type='button'
                variant='primary'
                color='dark'
                onClick={() => {
                  if (!user.parameters) {
                    const paramsAccess: any = [];

                    paramsData.map((param: any) => {
                      if (param.permissions.indexOf('VIEW') > -1) {
                        paramsAccess.push(param.id);
                      }
                    });
                    handleSaveUser(paramsAccess);
                  } else {
                    handleSaveUser();
                  }
                }}
              >
                Update User
              </Button>
            </div>
          </div>
        ) : null}
      </Dialog>
    </>
  );
};

export default UserDetailsModal;
