declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

const breakpoints = {
  values: {
    xs: 0,
    // sm: 600,
    // md: 960,
    // lg: 1280,
    // xl: 1920,
    mobile: 0,
    tablet: 1024,
    laptop: 1366,
    desktop: 1600
  }
};

export default breakpoints;
