import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  TimeScale,
  Legend
} from 'chart.js';
import {
  BoxPlotController,
  BoxAndWiskers
} from '@sgratzl/chartjs-chart-boxplot';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Chart } from 'react-chartjs-2';
import { useRecoilValue } from 'recoil';
import custom_palettes from 'theme/custom_palettes';
import { myChartJsTooltipHandler } from 'utils/chart';
import { cardSetAtom } from 'pages/Qc/state';

// =============================================================================

interface BoxPlotGraphProps {
  data: any[];
  param?: string;
  handleFocus?: any;
}

export default function BoxPlotGraph(props: BoxPlotGraphProps) {
  const [parameters, setParameters] = React.useState<string[]>([]);
  const cardSet = useRecoilValue(cardSetAtom);

  React.useEffect(() => {
    setParameters(
      cardSet.map((card: any) => `${card.parameterDesc} ${card.unit}`)
    );
  }, [cardSet]);

  return (
    <div
      style={{
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 30
      }}
    >
      {props.data[0] ? (
        <BoxPlot
          data={props.data[0].data}
          labels={props.data[0].labels}
          param={parameters[0]}
          index='0'
          handleFocus={props.handleFocus}
        />
      ) : (
        ''
      )}
      {props.data[1] ? (
        <>
          <div
            style={{
              marginBottom: 40
            }}
          />
          <BoxPlot
            data={props.data[1].data}
            labels={props.data[1].labels}
            param={parameters[1]}
            index='1'
            handleFocus={props.handleFocus}
          />
        </>
      ) : (
        ''
      )}
    </div>
  );
}

// =============================================================================
interface BoxPlotProps {
  data: any;
  labels: any;
  param: any;
  index: string;
  handleFocus?: any;
}

const BoxPlot = ({ data, labels, param, index, handleFocus }: BoxPlotProps) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    TimeScale,
    BoxPlotController,
    BoxAndWiskers,
    zoomPlugin
  );

  // @ts-ignore
  Tooltip.positioners.custom = function (elements, eventPosition) {
    /** @type {Chart.Tooltip} */
    var tooltip = this;

    /* ... */

    return {
      x: eventPosition.x,
      y: eventPosition.y
    };
  };

  const externalTooltipHandler = (context: any) => {
    myChartJsTooltipHandler(context, (tooltip: any) => {
      const data = tooltip.dataPoints[0];
      const title = data.dataset.label.split('(');
      const unit = title[1].replace(')', '');
      const values = data.formattedValue;
      const body = `<div class="label">${data.label}</div>
  <div class="values" style="color: ${data.dataset.borderColor}">
  <div class="q" style="font-size: 16px; margin-bottom: 6px;">${title[0]}</div>
  <div class="q">Maximum</div>
    <div class="a">${values.max} ${unit}</div>
    <div class="q">Q<sub>3</sub></div>
    <div class="a">${values.q3} ${unit}</div>
    <div class="q">Median</div>
    <div class="a">${values.median} ${unit}</div>
    <div class="q">Q<sub>1</sub></div>
    <div class="a">${values.q1} ${unit}</div>
    <div class="q">Minimum</div>
    <div class="a">${values.min} ${unit}</div>
  </div>
  `;
      return body;
    });
  };

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: (event: any, elements: any, chart: any) => {
      if (elements.length > 0) {
        const clickedBox = elements[0];
        handleFocus(clickedBox.index);
      }
    },
    plugins: {
      legend: {
        display: false,
        position: 'top',
        align: 'center',
        labels: {
          usePointStyle: false,
          font: {
            size: 15
          },
          color: custom_palettes.gray[800]
        }
      },
      tooltip: {
        enabled: false,
        position: 'custom',
        external: externalTooltipHandler
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x'
        },
        zoom: {
          wheel: {
            enabled: true,
            speed: 0.001
          },
          pinch: {
            enabled: false
          },
          mode: 'x'
        }
      }
    },
    scales: {
      x: {
        border: {
          color: custom_palettes.gray[800],
          width: 1
        },
        title: {
          display: true,
          text: `Time (UTC+8:00)`,
          color: custom_palettes.gray[800],
          padding: 5,
          font: {
            size: 16
          }
        }
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        grid: { display: false },
        border: {
          color:
            index === '0'
              ? custom_palettes.blue[800]
              : custom_palettes.green[500],
          width: 3
        },
        beginAtZero: false,
        title: {
          display: true,
          text: param,
          color: custom_palettes.gray[800],
          padding: 5,
          font: {
            size: 16
          }
        }
      }
    }
  };
  const boxplotData: any = {
    labels: labels,
    datasets: [
      {
        label: param,
        backgroundColor:
          index === '0'
            ? custom_palettes.blue[300]
            : custom_palettes.green[300],
        borderColor:
          index === '0'
            ? custom_palettes.blue[800]
            : custom_palettes.green[500],
        borderWidth: 2,
        outlierRadius: 0,
        coef: 0,
        meanStyle: 'circle',
        meanRadius: 0,
        medianColor: custom_palettes.white.main,
        barThickness: 25,
        maxBarThickness: 25,
        data: data
      }
    ]
  };
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: 400
      }}
    >
      <Chart
        type='boxplot'
        /*
      // @ts-ignore */
        data={boxplotData}
        options={options}
      />
    </div>
  );
};
