import { useState, SyntheticEvent } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import TabPanel from './TabPanel';

function a11yProps(index: number) {
  return {
    id: `controlled-tab-${index}`,
    'aria-controls': `controlled-tabpanel-${index}`
  };
}

export interface ControlledTabsProps {
  headers: string[];
  children: JSX.Element[];
}

export default function ControlledTabs({
  headers,
  children
}: ControlledTabsProps) {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='controlled-tabs'
        >
          {headers.map((header, index) => (
            <Tab
              key={`tab-header-${index}`}
              label={header}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {children.map((child, index) => (
        <TabPanel key={`tab-panel-${index}`} value={value} index={index}>
          {child}
        </TabPanel>
      ))}
    </Box>
  );
}
