import Button from '@mui/material/Button';
import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'universal-cookie';
import AddchartRoundedIcon from '@mui/icons-material/AddchartRounded';
import {
  cardSetAtom,
  startAtom,
  endAtom,
  graphDataAtom,
  isLoadingAtom,
  startQcAtom,
  endQcAtom
} from 'pages/Qc/state';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { queryData } from 'utils/api';

// =============================================================================
interface GetDataButtonProps {
  disabled?: boolean;
}

// =============================================================================
export default function GetDataButton({
  disabled = false
}: GetDataButtonProps) {
  const start = useRecoilValue(startAtom);
  const end = useRecoilValue(endAtom);
  const setStartQc = useSetRecoilState(startQcAtom);
  const setEndQc = useSetRecoilState(endQcAtom);
  const cardSet = useRecoilValue(cardSetAtom);
  const [graphData, setGraphData] = useRecoilState(graphDataAtom);
  const setIsLoading = useSetRecoilState(isLoadingAtom);
  const cookies = new Cookies();

  return (
    <div>
      <Button
        variant='secondary'
        color='dark'
        disabled={disabled}
        onClick={handleClick}
      >
        <AddchartRoundedIcon />
        Plot
      </Button>
    </div>
  );

  async function handleClick() {
    setGraphData([]);
    setIsLoading(true);

    // @ts-ignore
    const newData: any = await cardSet.reduce(
      async (tempData: any, card: any) => {
        const query = await queryData(start, end, card);
        // @ts-ignore
        const temp: any = await tempData;
        temp.push(query);
        return temp;
      },
      []
    );
    setStartQc({
      i: null,
      ts: null
    });
    setEndQc({
      i: null,
      ts: null
    });
    setGraphData(newData);
    setIsLoading(false);
  }
}
