import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import AppBar from 'components/AppBar';

interface Props {
  redirectPath?: any;
  children?: any;
}

export default function ProtectedRoute({
  redirectPath = '/login',
  children
}: Props) {
  const cookies = new Cookies();
  if (!cookies.get('admin-tokens')) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? (
    <>
      <AppBar /> {children}
    </>
  ) : (
    <>
      <AppBar />
      <Outlet />
    </>
  );
}
