import { z } from 'zod';

const OwnerSchema = z.object({
  ownerIdStr: z
    .string({
      required_error: 'Owner Id is required',
      invalid_type_error: 'Owner Id can only be a String'
    })
    .trim()
    .min(5, {
      message: 'Owner Id is required and cannot be less than 5 characters'
    })
    .max(10, { message: 'Owner Id cannot be more than 10 characters' })
    .refine((val) => /^[a-z0-9-_]+$/i.test(val), {
      message: 'Owner Id can only contain Alphabets and Numbers'
    }),
  name: z
    .string({
      required_error: 'Owner Name is required',
      invalid_type_error: 'Owner Name can only be a String'
    })
    .trim()
    .min(3, {
      message: 'Owner Name is required and cannot be less than 3 characters'
    })
    .max(100, { message: 'Owner Name cannot be more than 100 characters' }),
  admins: z
    .array(
      z
        .string({
          required_error: 'admin is required',
          invalid_type_error: 'admin can only be a String'
        })
        .trim()
        .min(1, { message: 'admin is required' })
        .refine((val) => /^[0-9]+$/i.test(val), {
          message: 'admin can only contain positive Numbers'
        }),
      {
        required_error: 'admins is required',
        invalid_type_error: 'admins can only be a Array of Strings'
      }
    )
    .nonempty({ message: 'Atleast one admin is required' }),
  users: z.array(
    z
      .string({
        invalid_type_error: 'Collaborator can only be a String'
      })
      .trim()
      .min(1, { message: 'Collaborator is required' })
      .refine((val) => /^[0-9]+$/i.test(val), {
        message: 'Collaborator can only contain positive Numbers'
      })
      .optional(),
    {
      invalid_type_error: 'Collaborator can only be a Array of Strings'
    }
  )
});

export const CreateOwnerSchema = OwnerSchema.transform(
  ({ ownerIdStr, ...rest }) => ({
    ownerId: ownerIdStr,
    ...rest
  })
);

export const UpdateOwnerSchema = OwnerSchema.extend({
  id: z
    .string({
      required_error: 'id is required',
      invalid_type_error: 'id can only be a String'
    })
    .trim()
    .min(1, { message: 'id is required' })
    .refine((val) => /^[0-9]+$/i.test(val), {
      message: 'id can only be a positive Number'
    })
}).transform(({ ownerIdStr, ...rest }) => ({
  ownerId: ownerIdStr,
  ...rest
}));
