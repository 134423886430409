interface props {
  data: any;
}

export default function BoxDeploymentView({ data }: props) {
  return (
    <div className='view-wrapper'>
      <h1>Overview</h1>
      <div className='row'>
        <div>Owner ID</div>
        <div>{data.ownerId}</div>
      </div>
      <br />

      <h1>Boxes</h1>
      {data.boxes.map((box: any, i: number) => (
        <div key={`box-${i}`}>
          <div className='table'>
            <div className='row'>
              <div>Id</div>
              <div>{box.id}</div>
            </div>
            <div className='row'>
              <div>Buoy Id</div>
              <div>{box.buoyId}</div>
            </div>
          </div>
          <br />

          {box.sensors && (
            <>
              <h4>Sensors</h4>
              <div className='table three-col'>
                <div className='row'>
                  <div>Id</div>
                  <div>Sensor class</div>
                  <div>Serial Number</div>
                  <div>Power control group</div>
                </div>
                {box.sensors.map((sensor: any, index: number) => (
                  <div className='row' key={`box-${i}-sensor-${index}`}>
                    <div>{sensor.id}</div>
                    <div>{sensor.class}</div>
                    <div>{sensor.serialNumber}</div>
                    <div>{sensor.powerControlGroup}</div>
                  </div>
                ))}
              </div>
              <br />
            </>
          )}
        </div>
      ))}
    </div>
  );
}
