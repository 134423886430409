import React from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { When } from 'react-if';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import moment from 'moment';

import { ParsedData } from 'pages/OnBoarding/type';
import OnBoardingView from './OnBoardingView';
import BoxDeploymentView from './BoxDeploymentView';
import { extractOnBoardingFile, unzip } from 'utils';
import Table from './Table';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const tableColumns: any = [
  {
    name: 'Created At',
    selector: (obj: any) =>
      moment(Number(obj.createdAt)).format('YYYY-MM-DD HH:mm A')
  },
  {
    name: 'Comment',
    selector: (obj: any) => obj.comment
  }
];

export default function DataPreview({
  type,
  file,
  comments,
  status
}: {
  type: any;
  file: any;
  comments: any;
  status: any;
}) {
  const [data, setData]: [any, any] = React.useState<ParsedData | null>(null);
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    const execute = async () => {
      if (file) {
        if (type === 'ON_BOARDING') {
          const entries = await unzip(file);

          const data = await extractOnBoardingFile(entries);
          setData(data);
        } else {
          if (typeof file === 'string') {
            setData(JSON.parse(file));
          } else {
            const reader = new FileReader();
            reader.onload = (e: any) => {
              const data = JSON.parse(e.target.result);
              setData(data);
            };
            reader.readAsText(file);
          }
        }
      }
    };

    execute();
  }, [file]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div hidden={value !== index} {...other}>
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };

  const getTabs = () => {
    if (type === 'ON_BOARDING') {
      return (
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label='Onboarding' />
              {data?.others.map((o: any, i: any) => (
                <Tab label={o.name} key={i} />
              ))}
              {status == 2 ? <Tab label='Comments' /> : null}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <OnBoardingView data={data?.onBoarding} />
          </TabPanel>
          {data?.others.map((o: any, index: any) => (
            <TabPanel value={value} index={++index} key={index}>
              <pre>{JSON.stringify(o.data, null, 2)}</pre>
            </TabPanel>
          ))}
          {status == 2 ? (
            <TabPanel value={value} index={data ? data.others.length + 1 : 1}>
              {comments.length ? (
                <Table columns={tableColumns} data={comments} />
              ) : (
                'No comments'
              )}
            </TabPanel>
          ) : null}
        </Box>
      );
    }
    if (type === 'BOX_DEPLOYMENT') {
      return (
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label='Box Deployment' />
              {status == 2 ? <Tab label='Comments' /> : null}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <BoxDeploymentView data={data} />
          </TabPanel>
          {status == 2 ? (
            <TabPanel value={value} index={1}>
              {comments.length ? (
                <Table columns={tableColumns} data={comments} />
              ) : (
                'No comments'
              )}
            </TabPanel>
          ) : null}
        </Box>
      );
    }

    if (type === 'CONFIGURATION') {
      return (
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label='Configuration' />
              {status == 2 ? <Tab label='Comments' /> : null}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </TabPanel>
          {status == 2 ? (
            <TabPanel value={value} index={1}>
              {comments.length ? (
                <Table columns={tableColumns} data={comments} />
              ) : (
                'No comments'
              )}
            </TabPanel>
          ) : null}
        </Box>
      );
    }
  };

  return (
    <>
      <When condition={!!data}>{getTabs()}</When>
    </>
  );
}
