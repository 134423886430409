import Box from '@mui/material/Box';
import DataTable from 'react-data-table-component';

const customStyles = {
  headRow: {
    style: {
      color: 'white',
      backgroundColor: '#6699CC' //blue
    }
  },
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 'bold',
      border: '1px solid #ddd', // add border to header cells
      justifyContent: 'center'
    }
  },
  rows: {
    style: {
      minHeight: '56px',
      '&:nth-child(even)': {
        backgroundColor: '#FFFFFF' //white
      },
      '&:nth-child(odd)': {
        backgroundColor: '#D0D5E8' //grey
      },
      border: '1px solid #ddd' // add border to rows
    }
  },
  cells: {
    style: {
      fontSize: '14px',
      paddingLeft: '8px',
      paddingRight: '8px',
      width: '20%',
      borderRight: '1px solid #ddd', // add right border to cells
      justifyContent: 'center'
    }
  }
};

export default function Table(props: any) {
  const { pagination = true } = props;
  return (
    <Box sx={{ width: '100%' }}>
      <DataTable
        title=''
        columns={[...props.columns]}
        data={props.data}
        pagination={pagination}
        customStyles={customStyles}
      />
    </Box>
  );
}
