import { atom, selector } from "recoil";
import api from 'utils/api';

type UserState = null | any;

export let emailState = atom({
  key: "emailState",
  default: "",
})

export let userState = selector({
  key: "userState",
  get: async ({get}) => {
    try {
      if (get(emailState) !== "") {
        const u = await api.get(`/admin/users/byEmail/${get(emailState)}`);
        return u.data || []
      }
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        alert('This email does not exist in the database.');
      } else if (error.response && error.response.status === 400) {
        alert('Please check that the email is in the correct format.');
      } else {
        alert(error.message);
      }
    }
  }
}) 

export let modalState = atom({
  key: "modalState",
  default: false,
})

export let searchState = atom({
  key: "searchState",
  default:""
})

export let userListState = selector({
  key: "userList",
  get: async ({get}) => {
    try {
      if (get(searchState) !== "") {
        const u = await api.get(`/admin/users/search/${get(searchState)}`);
        return u.data
      } else {
        const u =  await api.get(`/admin/users/`)
        return u.data
      }
    } catch (error: any) {
      alert("There might have been some problem with your input or the server" + error.message);
      return []
    }
  }
})