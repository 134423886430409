const getOrCreateTooltip = (chart: any) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div#chartjs-tooltip');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';

    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const myChartJsTooltipHandler = (context: any, htmlGenerator: any) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  const yAlign = tooltip.yAlign;
  const xAlign = tooltip.xAlign;

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove('top', 'bottom', 'center', 'left', 'right');
  // if (tooltipModel.yAlign || tooltipModel.xAlign) {
  tooltipEl.classList.add(yAlign);
  tooltipEl.classList.add(xAlign === 'center' ? 'left' : xAlign);

  // Set Text
  const body = htmlGenerator(tooltip);
  tooltipEl.innerHTML = body;

  // Tooltip height and width
  const { height, width } = tooltipEl.getBoundingClientRect();
  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  // Carets
  const caretY = tooltip.caretY;
  const caretX = tooltip.caretX;

  // Final coordinates
  let top = positionY + caretY - height;
  let left = positionX + caretX - width / 2;
  let space = 8; // The caret plus one pixle for some space, you can increase it.

  // yAlign could be: `top`, `bottom`, `center`
  if (yAlign === 'top') {
    top += height + space;
  } else if (yAlign === 'center') {
    top += height / 2;
  } else if (yAlign === 'bottom') {
    top -= space;
  }

  // xAlign could be: `left`, `center`, `right`
  if (xAlign === 'left') {
    left += width / 2 - space / 2;
    if (yAlign === 'center') {
      left += space * 2;
    }
  } else if (xAlign === 'right') {
    left -= width / 2;
    if (yAlign === 'center') {
      left -= space;
    } else {
      left += space;
    }
  } else if (xAlign === 'center') {
    left += width / 2 + space * 2;
  }

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = left + 'px';
  tooltipEl.style.top = top + 'px';

  // // Auto position tooltip, based on dimensions and boundary
  // const chartRect = chart.canvas.getBoundingClientRect();
  // const tooltipRect = tooltipEl.getBoundingClientRect();
  // if (tooltipRect.x + tooltipRect.width > chartRect.width) {
  //   console.log('Out of bounds');
  // }
};
