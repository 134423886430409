import { useRecoilValue } from 'recoil';
import { jwtData } from 'pages/OnBoarding/state';
import Qc from 'pages/Qc/Index';
import OnBoarding from 'pages/OnBoarding/Index';
import { CONSTANTS } from 'utils/constants';
import { currentRole } from 'pages/Login/state';

export default function Home() {
  const myCurrentRole: any = useRecoilValue(currentRole);

  if (myCurrentRole === CONSTANTS.ROLE.ROLE_COLLABORATOR) {
    return myCurrentRole ? <OnBoarding /> : null;
  }
  return <Qc />;
}
