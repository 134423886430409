import { Suspense } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import Cookies from 'universal-cookie';

import CustomContainer from 'components/CustomContainer';
import TextInput from 'components/TextInput';
import UsersTable from 'components/tables/UsersTable';
import UserDetailsModal from './UserDetailsModal';
import { isTokenAvailable } from 'pages/Login/state';

import { searchState, modalState } from "./state";

export default function Users() {
  const cookies = new Cookies();
  const setTokenState = useSetRecoilState(isTokenAvailable);
  const navigate = useNavigate();

  let setSearch = useSetRecoilState(searchState)
  let [modal, setModal] = useRecoilState(modalState)

  return (
    <>
      <CustomContainer>
        <div style={{ height: 32 }} />
        <form
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
          onSubmit={(e) => {
            e.preventDefault()
            let formData = new FormData(e.target as HTMLFormElement)
            setSearch(formData.get("search") as string)
          }}
        >
          <TextInput
            variant='outlined'
            id='search'
            name='search'
            placeholder='search term'
            containerStyle={{ width: '100%', maxWidth: 400 }}
          />
          <Button
            type='submit'
            variant='primary'
            color='dark'
            style={{
              marginLeft: 20
            }}
          >
            Search
          </Button>
        </form>
      </CustomContainer>

      <UsersTable />
      {modal ? (
        <Suspense fallback={<div>Loading...</div>}>
          <UserDetailsModal
            open={modal}
            onClose={() => setModal(false)}
          />
        </Suspense>

      ) : (
        ''
      )}
    </>
  );
}
