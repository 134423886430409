import { useState, useEffect } from 'react';
import api from 'utils/api';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import MyModal from 'components/common/modal';
import CustomContainer from 'components/CustomContainer';
import Cookies from 'universal-cookie';
import DataTable from 'react-data-table-component';
import { Button } from '@mui/material';
import ReviewModal from './ReviewModal';

const formatDate = (dateString: any) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

const customStyles = {
  headRow: {
    style: {
      color: 'white',
      backgroundColor: '#6699CC' //blue
    }
  },
  headCells: {
    style: {
      fontSize: '16px',
      fontWeight: 'bold',
      border: '1px solid #ddd', // add border to header cells
      justifyContent: 'center'
    }
  },
  rows: {
    style: {
      minHeight: '56px',
      '&:nth-child(even)': {
        backgroundColor: '#FFFFFF' //white
      },
      '&:nth-child(odd)': {
        backgroundColor: '#D0D5E8' //grey
      },
      border: '1px solid #ddd' // add border to rows
    }
  },
  cells: {
    style: {
      fontSize: '14px',
      paddingLeft: '8px',
      paddingRight: '8px',
      width: '20%',
      borderRight: '1px solid #ddd', // add right border to cells
      justifyContent: 'center'
    }
  }
};

export default function Alerts() {
  const [value, setValue] = useState(0);
  const [fetchedData, setFetchedData] = useState([]);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const cookies = new Cookies();
  const [refreshTable, setRefreshTable] = useState(false);
  const [editRow, setEditRow]: [any, any] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchTableData();
    setLoading(false);
  }, [refreshTable]);

  const columns: any = [
    {
      name: 'ID',
      selector: (fetchedData: any) => fetchedData['id']
    },
    {
      name: 'Created on',
      selector: (fetchedData: any) => formatDate(fetchedData['createdOn'])
    },
    {
      name: 'Location',
      selector: (fetchedData: any) => fetchedData['location']
    },
    {
      name: 'Sensor',
      selector: (fetchedData: any) => fetchedData['sensorClass']
    },
    {
      name: 'Parameter',
      selector: (fetchedData: any) => fetchedData['parameterName']
    },
    {
      name: 'Value',
      selector: (fetchedData: any) => fetchedData['value']
    },
    {
      name: 'Unit',
      selector: (fetchedData: any) => fetchedData['unit']
    },
    {
      name: 'Alert description',
      selector: (fetchedData: any) => fetchedData['description']
    },
    {
      name: 'Event',
      selector: (fetchedData: any) => fetchedData['eventName']
    },
    {
      name: 'Reviewed by',
      selector: (fetchedData: any) => fetchedData['reviewedByName']
    },
    {
      name: 'Reviewed on',
      selector: (fetchedData: any) => {
        if (fetchedData['reviewedOn']) {
          return formatDate(fetchedData['reviewedOn']);
        }
        return '';
      }
    },
    {
      name: 'Comments',
      selector: (fetchedData: any) => fetchedData.comment
    }
  ];

  const handleEdit = (row: any) => {
    setEditRow(row);
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleRefreshTable = () => {
    setRefreshTable(!refreshTable);
  };
  const TabPanel = (props: any) => {
    const { children, value, index, ...other } = props;

    return (
      <div hidden={value !== index} {...other}>
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };

  const getEventsData = async () => {
    try {
      const events = await api.get('/v1/mesn/events', {
        headers: {
          Authorization:
            'Bearer ' + cookies.get('admin-tokens').data.accessToken,
          'Content-Type': 'application/json'
        }
      });
      setEvents(events.data.sort((a: any, b: any) => b.id - a.id));
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTableData = async () => {
    try {
      const alerts = await api.get('/v1/mesn/alerts', {
        headers: {
          Authorization:
            'Bearer ' + cookies.get('admin-tokens').data.accessToken,
          'Content-Type': 'application/json'
        }
      });
      setFetchedData(alerts.data.sort((a: any, b: any) => b.id - a.id));
      getEventsData();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <CustomContainer>
      <MyModal open={isEditModalOpen} onClose={handleCloseEditModal}>
        <>
          <ReviewModal
            data={editRow}
            handleClose={handleCloseEditModal}
            handleRefresh={handleRefreshTable}
            events={events}
            actionType={value == 0 ? 'EDIT' : 'VIEW'}
          />
        </>
      </MyModal>
      <div style={{ height: 32 }} />
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label='Needs Review' />
            <Tab label='Reviewed' />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <DataTable
            title=''
            columns={[
              {
                name: 'Actions',
                minWidth: '150px',
                selector: (fetchedData: any) => (
                  <div>
                    <Button
                      type='button'
                      variant='secondary'
                      color='dark'
                      onClick={() => {
                        handleEdit(fetchedData);
                      }}
                    >
                      View/Edit
                    </Button>
                  </div>
                )
              },
              ...columns
            ]}
            data={
              fetchedData &&
              fetchedData.filter((data: any) => data.status == 'OPEN')
            }
            defaultSortFieldId={1}
            progressPending={loading}
            pagination
            customStyles={customStyles}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DataTable
            title=''
            columns={[
              {
                name: 'Actions',
                minWidth: '150px',
                selector: (fetchedData: any) => (
                  <div>
                    <Button
                      type='button'
                      variant='secondary'
                      color='dark'
                      onClick={() => {
                        handleEdit(fetchedData);
                      }}
                    >
                      View
                    </Button>
                  </div>
                )
              },
              ...columns
            ]}
            data={
              fetchedData &&
              fetchedData.filter((data: any) => data.status == 'REVIEWED')
            }
            defaultSortFieldId={1}
            progressPending={loading}
            pagination
            customStyles={customStyles}
          />
        </TabPanel>
      </Box>
    </CustomContainer>
  );
}
