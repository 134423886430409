import { atom } from 'recoil';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const errorMessage = atom({
  key: 'errorMessage',
  default: ''
});

export const isTokenAvailable = atom({
  key: 'isTokenAvailable',
  default: false
});

export const currentRole = atom({
  key: 'currentRole',
  default: ''
});
