import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';

// BUG: using props.variant in Typography props field lead to error
// 'string' is an assignable type when the file is a typescript file

export default function CustomLink(props) {
  const openInNewTabProps = props.newTab
    ? {
        target: '_blank',
        rel: 'noopener noreferrer'
      }
    : {};
  return (
    <Link
      underline='none'
      component={RouterLink}
      to={props.to ?? ''}
      onClick={props.onClick}
      sx={{
        margin: props.margin ?? 0,
        '&.MuiLink-root': {
          color: props.color
        },
        position: 'relative',
        // borderBottom: props.active ? "2px solid yellow" : "none",
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: -6,
          left: 0,
          width: '100%',
          height: 4,
          borderRadius: 2,
          background: props.active ? '#F5C64E' : 'transparent'
        }
      }}
      {...openInNewTabProps}
    >
      <Typography
        component={props.inline ? 'span' : null}
        variant={props.variant}
        fontSize={props.fontSize}
      >
        {props.children}
      </Typography>
    </Link>
  );
}
