import { ChangeEvent, useEffect, useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { Container, Typography, Button, Stack, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { ControlledDataGrid } from './ControlledDataGrid';
import ControlledTabs from './ControlledTabs';
import { isEmpty, isNil, sumBy } from 'lodash';
import { Moment } from 'moment';
import api from 'utils/api';

const validateFile = (file: File) => {
  const isExcel =
    file.type === 'application/vnd.ms-excel' ||
    file.type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  const isLt300M = file.size / 1024 / 1024 < 300;
  if (!isExcel) {
    console.error('Only Excel format is supported!');
  }
  if (!isLt300M) {
    console.error('Max file size allowed is 300MB!');
  }

  return isExcel && isLt300M;
};

interface DataSheetProps {
  sheetName: string;
  sheetIndex: number;
  ws: XLSX.WorkSheet;
}

const checkStatus =
  'Check data table. Then press SUBMIT to commit to database.';
const doneStatus =
  'Dataset successfully committed to database. Please check the submitted data below.';
const submittingStatus = 'Submitting dataset to database...';

function CruiseDataUpload() {
  const uploadButton = useRef<any>();
  const [currentFile, setCurrentFile]: [any, any] = useState<File>();
  const [results, setResults] = useState<string>('');

  const [status, setStatus] = useState('');
  const [sheets, setSheets] = useState<DataSheetProps[]>([]);
  const [sheetJson, setSheetJson] = useState([]);

  const processFileLocally = (file: File) => {
    const newSheets: any = [];
    const newSheetJson: any = [];
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: 'binary',
        cellDates: true,
        cellNF: true,
        cellText: true
      });

      const noOfSheets = wb.SheetNames.length;
      for (let i = 0; i < noOfSheets; i++) {
        const wsname = wb.SheetNames[i];
        if (wsname.toLowerCase() !== 'main sheet') continue;
        const ws = wb.Sheets[wsname];
        const newSheet: DataSheetProps = {
          sheetIndex: newSheets.length + i,
          sheetName: file.name,
          ws
        };
        newSheets.push(newSheet);
        newSheetJson.push({
          name: newSheet.sheetName,
          json: XLSX.utils.sheet_to_json(ws, { header: 1 })
        });
      }

      setSheets(newSheets);
      setSheetJson(newSheetJson);
    };

    reader.readAsBinaryString(file);
  };

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files) {
      const file = event?.target?.files[0];
      if (validateFile(file)) {
        setCurrentFile(file);
      }
    }
  };

  const clearFileInput = () => {
    setCurrentFile(undefined);
    setSheets([]);
    setSheetJson([]);

    uploadButton.current.value = '';
  };

  const submitData = async () => {
    try {
      const fd = new FormData();
      fd.append('file', currentFile);
      const response = await api.post('/admin/cruiseData', fd, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      alert(response.data.message);
      // const createdCount = sumBy(response, 'data.createdCount');
      // const deletedCount = sumBy(response, 'data.deletedCount');
      // setResults(`Created: ${createdCount} - Overwritten: ${deletedCount}`);
      return true;
    } catch (err: any) {
      alert(`Error: ${err.response.data.message}`);
    }
  };

  useEffect(() => {
    if (currentFile) {
      setStatus(checkStatus);
      setResults('');
      processFileLocally(currentFile);
    }
  }, [currentFile]);

  return (
    <Container style={{ display: 'flex', flexDirection: 'column' }}>
      <Stack direction='row' p={2} spacing={2}>
        <Button onClick={() => uploadButton.current?.click()}>
          Select File
          <input
            ref={uploadButton}
            type='file'
            hidden
            onChange={changeHandler}
            accept='application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          />
        </Button>
        <Button
          disabled={sheets.length === 0}
          variant={sheets.length === 0 ? 'secondary' : 'primary'}
          onClick={async () => {
            setStatus(submittingStatus);
            const success = await submitData();

            if (!success) {
              return;
            }

            setStatus(doneStatus);
          }}
        >
          Submit
        </Button>
        <Button
          disabled={sheets.length === 0}
          onClick={() => {
            setStatus('');
            clearFileInput();
          }}
        >
          Clear
        </Button>
      </Stack>

      <Stack p={2}>
        <Typography color='red'>{status}</Typography>
        {!isEmpty(results) && <Typography>{results}</Typography>}
        <ControlledTabs
          headers={sheets.map((sheet) => sheet.sheetName)}
          children={sheets.map((sheet, index) => (
            <ControlledDataGrid
              key={`data-grid-${index}`}
              worksheet={sheet.ws}
              updateWorksheetJson={(index, ws) => {
                setSheetJson(
                  sheetJson.map((_sheetJson, _index) =>
                    _index === index
                      ? (XLSX.utils.sheet_to_json(ws, { header: 1 }) as any)
                      : _sheetJson
                  ) as any
                );
              }}
              sheetIndex={sheet.sheetIndex}
            />
          ))}
        />
      </Stack>
    </Container>
  );
}

export default CruiseDataUpload;
