import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

export default function MyModal(props: any) {
  const { children, ...rest } = props;
  return (
    <Modal {...rest}>
      <>
        <IconButton
          onClick={props.onClose}
          tabIndex={0}
          style={{
            position: 'absolute',
            right: '150px',
            top: '50px',
            zIndex: 10
          }}
        >
          <CloseIcon sx={{ color: 'blue.800' }} />
        </IconButton>
        {children}
      </>
    </Modal>
  );
}
