import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { startAtom, endAtom } from 'pages/Qc/state';

// =============================================================================
export default function GraphTimeRange() {
  const [start, setStart] = useRecoilState(startAtom);
  const [end, setEnd] = useRecoilState(endAtom);

  // useEffect(() => {
  //   setStart(moment().subtract(2, 'weeks').startOf('day').unix() * 1000);
  // }, [start]);

  // useEffect(() => {
  //   setEnd(moment(end).endOf('day').unix() * 1000);
  // }, [end]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        margin: 48,
        marginBottom: 0
      }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          renderInput={(props: any) => <TextField {...props} />}
          label='Start'
          value={moment(start).format('YYYY-MM-DD HH:mm:ss')}
          inputFormat='DD-MMM-YYYY'
          onChange={(newValue: any) => {
            setStart(
              moment(newValue.unix() * 1000)
                .startOf('day')
                .unix() * 1000
            );
          }}
        />
        <div style={{ width: 16 }} />
        <DatePicker
          renderInput={(props: any) => <TextField {...props} />}
          label='End'
          value={moment(end).format('YYYY-MM-DD HH:mm:ss')}
          inputFormat='DD-MMM-YYYY'
          onChange={(newValue: any) => {
            setEnd(
              moment(newValue.unix() * 1000)
                .endOf('day')
                .unix() * 1000
            );
          }}
        />
      </LocalizationProvider>
    </div>
  );
}
