import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/theme';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import Login from 'pages/Login/Index';
import Home from 'pages/Home/Index';
import EventsLog from 'pages/EventsLog/Index';
import Alerts from 'pages/Alerts/Index';
import Users from 'pages/Users/Index';
import Owners from 'pages/Owners/Index';
import Parameters from 'pages/Parameters/Index';
import _ from 'lodash';
import OnBoarding from 'pages/OnBoarding/Index';
import ProtectedRoute from 'components/ProtectedRoute';
import Modal from '@mui/material/Modal';
import api, { fetchParams } from 'utils/api';
import CircularProgress from '@mui/material/CircularProgress';
import CruiseDataUpload from 'pages/CruiseDataUpload/Index';
import { useSetParams } from 'pages/Qc/state';

function GlobalModal() {
  return (
    <Modal open={true}>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CircularProgress color='primary' size={40} />
      </div>
    </Modal>
  );
}

function MyRoutes() {
  const setParams = useSetParams();

  useEffect(() => {
    const execute = async () => {
      const params: any = await fetchParams();
      setParams(params);
    };

    execute();
  }, []);

  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
        path='/'
      />
      <Route
        element={
          <ProtectedRoute>
            <OnBoarding />
          </ProtectedRoute>
        }
        path='/instrument-on-boarding'
      />
      <Route
        element={
          <ProtectedRoute>
            <EventsLog />
          </ProtectedRoute>
        }
        path='/events'
      />
      <Route
        element={
          <ProtectedRoute>
            <CruiseDataUpload />
          </ProtectedRoute>
        }
        path='/cruise-data-upload'
      />
      <Route
        element={
          <ProtectedRoute>
            <Alerts />
          </ProtectedRoute>
        }
        path='/alerts'
      />
      <Route
        element={
          <ProtectedRoute>
            <Users />
          </ProtectedRoute>
        }
        path='/users'
      />
      <Route
        element={
          <ProtectedRoute>
            <Owners />
          </ProtectedRoute>
        }
        path='/owners'
      />
      <Route
        element={
          <ProtectedRoute>
            <Parameters />
          </ProtectedRoute>
        }
        path='/parameters'
      />
      <Route element={<Login />} path='/login' />
    </Routes>
  );
}

function App() {
  const [showModal, setShowModal] = useState(false);

  api.interceptors.request.use(
    (config) => {
      setShowModal(true);
      return config;
    },
    (error) => {
      setShowModal(false);
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => {
      setShowModal(false);
      return response;
    },
    (error) => {
      setShowModal(false);
      return Promise.reject(error);
    }
  );

  return (
    <div>
      {showModal && <GlobalModal />}
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <RecoilRoot>
            <MyRoutes />
          </RecoilRoot>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
