import { createTheme } from '@mui/material/styles';
import custom_palettes from './custom_palettes';
import custom_typographys from './custom_typographys';
import custom_buttons from './custom_buttons';
import custom_breakpoints from './custom_breakpoints';
import { custom_tabs, custom_tab } from './custom_tabs';

declare module '@mui/material/styles' {
  interface Theme {}
  // allow configuration using `createTheme`
  interface ThemeOptions {}
}

// =============================================================================
const theme = createTheme({
  palette: custom_palettes,
  typography: custom_typographys,
  breakpoints: custom_breakpoints,
  components: {
    MuiButton: custom_buttons as any,
    MuiTabs: custom_tabs as any,
    MuiTab: custom_tab as any
  }
});

export default theme;
