import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function BasicSelect(props: any) {
  const [selected, setSelected] = useState(
    props.selected ? props.selected : ''
  );

  useEffect(() => {
    if (props.selected) {
      props.onChange(props.selected);
    }
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    setSelected(event.target.value as string);
    props.onChange(event.target.value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id='simple-select-label'>{props.label}</InputLabel>
        <Select
          labelId='simple-select-label'
          id='simple-select'
          value={selected}
          label={props.label}
          onChange={handleChange}
          {...(props.menuProps && { MenuProps: props.menuProps })}
        >
          {props.children}
        </Select>
      </FormControl>
    </Box>
  );
}
