export const CONSTANTS = {
  ROLE: {
    ROLE_USER: 'ROLE_USER',
    ROLE_ADMIN: 'ROLE_ADMIN',
    ROLE_BUOY_MAINTAINER: 'ROLE_BUOY_MAINTAINER',
    ROLE_COLLABORATOR: 'ROLE_COLLABORATOR'
  },
  STATUS: {
    APPROVED: 'APPROVED',
    PENDING_REVIEW: 'PENDING_REVIEW',
    REJECTED: 'REJECTED',
    DEPRECATED: 'DEPRECATED'
  },
  ACTION: {
    APPROVE: 'APPROVE',
    REJECT: 'REJECT'
  }
};
