import { Select, FormHelperText, FormControl, InputLabel } from '@mui/material';
import React, { useState } from 'react';

function App(props: any) {
  const [selected, setSelected] = useState(
    props.selected ? props.selected : ''
  );

  const selectionChangeHandler = (event: any) => {
    setSelected(event.target.value);
    props.handleSelect(event.target.value);
  };

  return (
    <div className='dropdown'>
      <FormControl style={{ marginBottom: 32, width: 400 }}>
        <InputLabel>{props.title}</InputLabel>
        <Select value={selected} onChange={selectionChangeHandler}>
          {props.children}
        </Select>
        <FormHelperText>{props.helperText}</FormHelperText>
      </FormControl>
    </div>
  );
}

export default App;
