import React from 'react';
import ChartOptions from './ChartOptions';
import Grid from '@mui/material/Grid';
import SideDrawer from './SideDrawer/Index';

export default function Qc() {
  return (
    <div>
      <Grid container>
        <Grid item xs={3}>
          <SideDrawer />
        </Grid>

        <Grid item xs={9}>
          <ChartOptions />
        </Grid>
      </Grid>
    </div>
  );
}
