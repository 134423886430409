import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import custom_palettes from 'theme/custom_palettes';
import moment from 'moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Decimation
} from 'chart.js';
import 'chartjs-adapter-moment';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Line } from 'react-chartjs-2';
import { myChartJsTooltipHandler } from 'utils/chart';
import { usePrevious } from 'utils';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import momentTZ from 'moment-timezone';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  startAtom,
  endAtom,
  cardSetAtom,
  startQcAtom,
  endQcAtom
} from 'pages/Qc/state';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  zoomPlugin,
  Decimation
);

// @ts-ignore
Tooltip.positioners.custom = function (elements, eventPosition) {
  /** @type {Chart.Tooltip} */
  var tooltip = this;

  /* ... */

  return {
    x: eventPosition.x,
    y: eventPosition.y
  };
};

const externalTooltipHandler = (context: any) => {
  myChartJsTooltipHandler(context, (tooltip: any) => {
    const data = tooltip.dataPoints[0].raw.tooltip;
    const dataset = tooltip.dataPoints[0].dataset;
    const title = dataset.label.split('(');
    const label = `${momentTZ(data.label).format('DD MMM YYYY, HH:mm:ss')}`;
    const body = `<div class="label">${label}</div>
    <div class="values" style="color: ${dataset.backgroundColor}">
      <div class="q">${title[0]}</div>
      <div class="a">${data.value} ${title[1].replace(')', '')}</div>
    </div>
    `;
    return body;
  });
};

const data: any = {
  datasets: [
    {
      yAxisID: 'y',
      borderColor: (context: any) => {
        const index = context.dataIndex;
        const obj = context.dataset.data[index];
        if (obj) {
          if (obj.qcFlag == 0) {
            return custom_palettes.yellow[400];
          }
          if (obj.qcFlag == 1) {
            return custom_palettes.green[500];
          }
          if (obj.qcFlag == 2) {
            return custom_palettes.green[50];
          }
          if (obj.qcFlag == 3) {
            return custom_palettes.error.main;
          }
        }
        return custom_palettes.yellow[400];
      },
      backgroundColor: custom_palettes.yellow[400],
      pointStyle: 'circle',
      pointBackgroundColor: custom_palettes.white.main,
      segment: {
        borderColor: (ctx: any) => {
          if (ctx.p0.raw.qcFlag == 0 && ctx.p1.raw.qcFlag == 0) {
            return custom_palettes.yellow[400];
          }
          if (ctx.p0.raw.qcFlag == 1 && ctx.p1.raw.qcFlag == 1) {
            return custom_palettes.green[500];
          }
          if (ctx.p0.raw.qcFlag == 2 && ctx.p1.raw.qcFlag == 2) {
            return custom_palettes.green[50];
          }
          if (ctx.p0.raw.qcFlag == 3 && ctx.p1.raw.qcFlag == 3) {
            return custom_palettes.error.main;
          }
          return undefined;
        },
        borderDash: (ctx: any) => (ctx.p0.raw.qcFlag == 0 ? [3, 3] : undefined)
      }
    }
  ]
};

// ===========================================================================
interface LineGraphProps {
  data: any[];
  xAxisLabel?: string;
  xAxisUnit?: string;
  yAxisLabel?: string;
  yAxisUnit?: string;
  style?: React.CSSProperties;
  accuracy?: any;
  focus?: number;
  setFocus?: any;
}

export default function LineGraph(props: LineGraphProps) {
  const startQcRef = useRef<any>();
  const endQcRef = useRef<any>();
  // @ts-ignore
  startQcRef.current = {
    i: null,
    ts: null
  };
  // @ts-ignore
  endQcRef.current = {
    i: null,
    ts: null
  };

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    parsing: false,
    animation: true,
    onClick: (event: any, elements: any, chart: any) => {
      const dataset = chart.data.datasets[0];

      if (!elements.length) {
        if (startQcRef.current.i || endQcRef.current.i) {
          // @ts-ignore
          startQcRef.current = {
            i: null,
            ts: null
          };
          // @ts-ignore
          endQcRef.current = {
            i: null,
            ts: null
          };

          setStartQc(startQcRef.current);
          setEndQc(endQcRef.current);
        }
        dataset.pointBackgroundColor = dataset.data.map(
          (v: any, i: any) => custom_palettes.white.main
        );
        dataset.segment = {
          ...dataset.segment,
          borderColor: (ctx: any) => {
            if (ctx.p0.raw.qcFlag == 0) {
              return custom_palettes.yellow[400];
            }
            if (ctx.p0.raw.qcFlag == 1) {
              return custom_palettes.green[500];
            }
            if (ctx.p0.raw.qcFlag == 2) {
              return custom_palettes.green[50];
            }
            if (ctx.p0.raw.qcFlag == 3) {
              return custom_palettes.error.main;
            }
            return undefined;
          }
        };
        chart.update();
        return;
      }

      if (!Number.isInteger(startQcRef.current.i)) {
        if (elements[0] && Number.isInteger(elements[0]?.index)) {
          dataset.pointBackgroundColor = dataset.data.map((v: any, i: any) => {
            return i == elements[0]?.index
              ? custom_palettes.blue[900]
              : custom_palettes.white.main;
          });

          startQcRef.current = {
            i: elements[0].index,
            ts: elements[0].element.raw.x
          };
        }
      } else {
        if (elements[0] && Number.isInteger(elements[0]?.index)) {
          let eQc = {
            i: elements[0].index,
            ts: elements[0].element.raw.x
          };
          // @ts-ignore
          if (elements[0]?.index < startQcRef.current.i) {
            eQc = { ...startQcRef.current };
            startQcRef.current = {
              i: elements[0]?.index,
              ts: elements[0].element.raw.x
            };
          }
          endQcRef.current = eQc;
        }
      }

      if (Number.isInteger(endQcRef.current.i)) {
        dataset.pointBackgroundColor = dataset.data.map((v: any, i: any) =>
          // @ts-ignore
          i >= startQcRef.current.i && i <= endQcRef.current.i
            ? custom_palettes.blue[900]
            : custom_palettes.white.main
        );

        dataset.segment = {
          ...dataset.segment,
          borderColor: (ctx: any) => {
            if (
              ctx.p0DataIndex >= startQcRef.current.i &&
              ctx.p1DataIndex <= endQcRef.current.i
            ) {
              return custom_palettes.blue[900];
            }
            if (ctx.p0.raw.qcFlag == 0) {
              return custom_palettes.yellow[400];
            }
            if (ctx.p0.raw.qcFlag == 1) {
              return custom_palettes.green[500];
            }
            if (ctx.p0.raw.qcFlag == 2) {
              return custom_palettes.green[50];
            }
            if (ctx.p0.raw.qcFlag == 3) {
              return custom_palettes.error.main;
            }
            return undefined;
          }
        };
      }
      chart.update();

      setStartQc(startQcRef.current);
      setEndQc(endQcRef.current);
    },
    plugins: {
      legend: {
        display: false,
        position: 'top',
        align: 'center',
        labels: {
          usePointStyle: false,
          font: {
            size: 15
          },
          color: custom_palettes.gray[800]

          // generateLabels: (chart: any) => {
          //   const firstLabelText = chart.data.datasets[0].label || '';
          //   const secondLabelText =
          //     chart.data.datasets.length > 1 ? chart.data.datasets[1].label : '';
          //   return [
          //     {
          //       fontColor: custom_palettes.gray[800],
          //       datasetIndex: 0,
          //       pointStyle: 'circle',
          //       text: firstLabelText
          //     },
          //     {
          //       fontColor: custom_palettes.gray[800],
          //       datasetIndex: 1,
          //       pointStyle: 'rect',
          //       text: secondLabelText
          //     }
          //   ];
          // }
        }
      },
      tooltip: {
        enabled: false,
        position: 'custom',
        external: externalTooltipHandler
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x'
        }
        // zoom: {
        //   wheel: {
        //     enabled: true,
        //     speed: 0.01
        //   },
        //   pinch: {
        //     enabled: false
        //   },
        //   mode: 'x'
        //   // TODO: Below code is used to restrict the user to
        //   // no to zoom-in to a level where we are not able to display granular data
        //   // onZoomStart: ({ chart }: { chart: any }) => {
        //   //   console.log(chart.scales['x'].ticks.length);
        //   //   console.log(chart.scales['x'].ticks.length >= 2);
        //   //   return chart.scales['x'].ticks.length >= 2;
        //   // }
        // }
      },
      decimation: {
        enabled: true,
        algorithm: 'lttb',
        samples: 100,
        threshold: 100
      }
    },
    elements: {
      point: {
        radius: 3
      },
      line: {
        borderWidth: 2
      }
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'hour'
        },
        ticks: {
          callback: function (value: any, index: any, ticks: any) {
            return moment(value).format('DD MMM YYYY, HH:mm');
          }
        },
        border: {
          color: custom_palettes.gray[800],
          width: 1
        },
        title: {
          display: true,
          text: `Time (UTC+8:00)`,
          color: custom_palettes.gray[800],
          padding: 5,
          font: {
            size: 16
          }
        }
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        grid: { display: false },
        border: {
          color: custom_palettes.yellow[400],
          width: 3
        }
      }
    }
  };

  const startTime: any = useRecoilValue(startAtom);
  const endTime = useRecoilValue(endAtom);
  const cardSet: any = useRecoilValue(cardSetAtom);
  const [parameters, setParameters] = useState<string[]>(
    cardSet.map((card: any) => `${card.parameterDesc} ${card.unit}`)
  );
  const setStartQc = useSetRecoilState(startQcAtom);
  const setEndQc = useSetRecoilState(endQcAtom);
  const [chartOptions, setChartOptions] = useState(options);
  const prevFocus = usePrevious(props.focus);
  const chartRef = useRef();
  useEffect(() => {
    if (chartRef && chartRef.current && props.data.length) {
      // @ts-ignore
      const chart: any = chartRef.current;
      const co = { ...chartOptions };

      if (props.data.length === 2) {
        chart.data.datasets[0].label = parameters[0];
        co.scales.y.title = {
          display: true,
          text: parameters[0],
          color: custom_palettes.gray[800],
          padding: 5,
          font: {
            size: 16
          }
        };
        chart.data.datasets[0].data = props.data[0];
        chart.data.datasets[1] = {
          label: parameters[1],
          yAxisID: 'y1',
          data: props.data[1],
          borderColor: custom_palettes.green[500],
          backgroundColor: custom_palettes.green[500],
          pointStyle: 'rect',
          pointBackgroundColor: custom_palettes.white.main,
          segment: {
            borderDash: (ctx: any) =>
              ctx.p0.raw.qcFlag == 0 ? [3, 3] : undefined
          }
        };
        co.scales.y1.title = {
          display: true,
          text: parameters[1],
          color: custom_palettes.gray[800],
          padding: 5,
          font: {
            size: 16
          }
        };
      } else {
        chart.data.datasets[0].label = parameters[0];
        co.scales.y.title = {
          display: true,
          text: parameters[0],
          color: custom_palettes.gray[800],
          padding: 5,
          font: {
            size: 16
          }
        };
        chart.data.datasets[0].data = props.data[0];

        if (chart.data?.datasets?.length == 2) {
          chart.data.datasets.pop();
        }
      }
      co.plugins.legend.display = true;
      co.scales.x.min = startTime;
      // @ts-ignore
      if (!cardSet[0].isCruiseParam) {
        co.scales.x.max = startTime + 3600000;
      }

      setChartOptions(co);
      chart.update();
    }
  }, [props.data]);

  useEffect(() => {
    setParameters(
      cardSet.map((card: any) => `${card.parameterDesc} ${card.unit}`)
    );
    const co: any = { ...chartOptions };
    const length = Object.keys(cardSet).length;
    if (length === 2) {
      co['scales']['y1'] = {
        type: 'linear',
        display: true,
        position: 'right',
        grid: { display: false },
        border: {
          color: custom_palettes.green[500],
          width: 3
        }
      };
    } else if (length === 1) {
      delete co['scales']['y1'];
    } else {
      co.plugins.legend.display = false;
    }
    setChartOptions(co);
    // return () => {
    //   if (chartRef && chartRef.current) {
    //     // @ts-ignore
    //     chartRef.current.destroy();
    //   }
    // };
  }, [cardSet, props.data]);

  useEffect(() => {
    const maxTime = getMaxTime();
    const co = { ...chartOptions };
    co.plugins.zoom.limits = {
      x: {
        min: moment(startTime).valueOf(),
        max: moment(maxTime).valueOf()
      }
    };
    setChartOptions(co);
  }, [props.data]);

  useEffect(() => {
    if (props.focus && props.focus !== prevFocus) {
      setTimeout(() => {
        if (chartRef && chartRef.current) {
          const chart: any = chartRef.current;
          chart.options.scales.x.min = props.focus;
          // @ts-ignore
          if (!cardSet[0].isCruiseParam) {
            chart.options.scales.x.max = startTime + 3600000;
          }
          chart.update();

          props.setFocus();
        }
      }, 500);
    }
  }, [props.focus]);

  const handleLeftClick = () => {
    if (chartRef && chartRef.current) {
      const chart: any = chartRef.current;

      const currentLeft = chart.options.scales.x.min;
      const nextLeft = currentLeft - 3600000;
      if (nextLeft >= startTime) {
        chart.options.scales.x.max = currentLeft;
        chart.options.scales.x.min = nextLeft;
        chart.update();
      }
    }
  };

  const handleRightClick = () => {
    if (chartRef && chartRef.current) {
      const chart: any = chartRef.current;

      const currentRight = chart.options.scales.x.max;
      const nextRight = currentRight + 3600000;
      if (nextRight <= endTime) {
        chart.options.scales.x.min = currentRight;
        chart.options.scales.x.max = nextRight;
        chart.update();
      }
    }
  };

  return (
    <div
      style={{
        paddingBottom: 30,
        ...props.style
      }}
    >
      <div
        style={{
          position: 'relative',
          height: 400,
          display: 'flex'
        }}
      >
        {cardSet && cardSet[0] && cardSet[0].isCruiseParam ? null : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'rgba(0,0,0,0.1)',
              marginLeft: 10,
              cursor: 'pointer'
            }}
            onClick={handleLeftClick}
          >
            <ArrowLeftIcon />
          </div>
        )}
        <div
          style={{
            flex: 1
          }}
        >
          <Line ref={chartRef} options={chartOptions} data={data} />
        </div>
        {cardSet && cardSet[0] && cardSet[0].isCruiseParam ? null : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'rgba(0,0,0,0.1)',
              marginRight: 10,
              cursor: 'pointer'
            }}
            onClick={handleRightClick}
          >
            <ArrowRightIcon />
          </div>
        )}
      </div>
    </div>
  );

  function getMaxTime(): Date {
    const allTimes = props.data.flatMap((d) => d.map((dt: any) => dt.x));
    const maxTime = Math.max.apply(null, allTimes);

    // @ts-ignore
    return props?.data?.length ? new Date(maxTime) : moment(endTime).toDate();
  }
}
