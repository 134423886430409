import { useRef, useEffect } from 'react';
import JSZip from 'jszip';

export const getAppVersion = () => {
  const now = new Date();
  const month = now.getMonth() + 1;
  const date = now.getDate();
  const dateStr = `${now.getFullYear()}${month < 10 ? '0' + month : month}${
    date < 10 ? '0' + date : date
  }`;
  const BUILD_NUM = import.meta.env.VITE_BUILD_NUM || '';

  return BUILD_NUM ? ` v1.0-(${dateStr}-${BUILD_NUM})` : '';
};

export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const unzip = async (zipFile: any) => {
  if (zipFile && zipFile.size !== 0) {
    // Create a new zip file instance
    const unpackedZip = new JSZip();
    // Load the file into the zip file
    await unpackedZip.loadAsync(zipFile);
    return unpackedZip;
  }

  return {};
};

export const extractOnBoardingFile = async (unpackedZip: any) => {
  const res: any = {
    onBoarding: null,
    others: []
  };

  if (unpackedZip && unpackedZip.files) {
    let zipEntry: any;
    for (zipEntry in unpackedZip.files) {
      const entry = unpackedZip.files[zipEntry];
      const filename = entry.name.toLowerCase();
      if (
        filename.includes('onboarding.') &&
        filename.includes('.json') &&
        !filename.includes('__MACOSX/'.toLowerCase())
      ) {
        const jsonContent = await entry.async('string');

        // parse the JSON content
        const jsonData = JSON.parse(jsonContent);
        res.onBoarding = jsonData;
      } else if (
        !filename.includes('__MACOSX/'.toLowerCase()) &&
        filename.includes('.json')
      ) {
        const jsonContent = await entry.async('string');

        // parse the JSON content
        const jsonData = JSON.parse(jsonContent);
        res.others.push({ name: entry.name, data: jsonData });
      }
    }
  }
  return res;
};
