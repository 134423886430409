import { useState, useEffect } from 'react';

import MyModal from 'components/common/modal';
import { Button } from '@mui/material';
import DataTable from 'react-data-table-component';
import Cookies from 'universal-cookie';
import CustomContainer from 'components/CustomContainer';
import AddEventTableModal from 'components/modals/AddEventModal';
import EditEventTableModal from 'components/modals/EditEventTableModal';
import api from 'utils/api';

function EventsLog() {
  const [fetchedData, setFetchedData] = useState<FetchedData[]>([]);
  const [loading, setLoading] = useState(false);
  const [editRow, setEditRow] = useState<DataRow | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const cookies = new Cookies();

  const EVENTS_API = '/admin/events';

  // =============================================================================
  // Row data specification
  type DateTimeString = string;

  // Incoming data from GET
  type FetchedData = {
    comment: string;
    createdBy: number;
    createdByName: string;
    createdOn: string;
    endTs: string;
    id: number;
    location: string;
    name: string;
    startTs: string;
  };

  // Display columns
  type DataRow = {
    ID: number;
    'Start time': DateTimeString;
    'End time': DateTimeString;
    Location: string;
    Event: string;
    'Logged By': string;
    Comments: string;
  };

  // =============================================================================
  // Table Specification
  const columns = [
    {
      name: 'ID',
      selector: (fetchedData: any) => fetchedData['id'],
      sortable: false
    },
    {
      name: 'Start Time',
      selector: (fetchedData: any) => formatDate(fetchedData['startTs']),
      sortable: false
    },
    {
      name: 'End Time',
      selector: (fetchedData: any) => formatDate(fetchedData['endTs']),
      sortable: false
    },
    {
      name: 'Location',
      selector: (fetchedData: any) => fetchedData['location'],
      sortable: false
    },
    {
      name: 'Event',
      selector: (fetchedData: any) => fetchedData['name'],
      sortable: false
    },
    {
      name: 'Logged By',
      selector: (fetchedData: any) => fetchedData['createdByName'],
      sortable: false
    },
    {
      name: 'Actions',
      selector: (fetchedData: any) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: 5
          }}
        >
          <Button
            type='button'
            variant='secondary'
            color='dark'
            onClick={() => {
              handleDelete(fetchedData);
            }}
          >
            Delete
          </Button>
          <div
            style={{
              marginBottom: '10px'
            }}
          />
          <Button
            type='button'
            variant='primary'
            color='dark'
            onClick={() => {
              handleEdit(fetchedData);
            }}
          >
            Edit
          </Button>
        </div>
      )
    },
    {
      name: 'Comments',
      selector: (fetchedData: any) => fetchedData.comment
    }
  ];

  // =============================================================================
  // useEffect functions for table actions
  useEffect(() => {
    setLoading(true);
    fetchTableData();
    //cleanTableData();
    setLoading(false);
  }, [refreshTable]);

  const handleEdit = (row: DataRow) => {
    setEditRow(row);
    setIsEditModalOpen(true);
  };

  const handleDelete = async (row: any) => {
    try {
      await api.delete(EVENTS_API + '/' + row.id, {
        headers: {
          Authorization: 'Bearer ' + cookies.get('admin-tokens').accessToken,
          'Content-Type': 'application/json'
        }
      });
      handleRefreshTable();
    } catch (err: any) {
      window.alert('Server error, please try again later!');
    }
  };

  const handleAddEvent = () => {
    setIsAddModalOpen(true);
  };
  const handleCloseAddModal = () => {
    setIsAddModalOpen(false);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  // const handleDelete = (row: DataRow) => {
  //   setFetchedData((prevData) => {
  //     const index = prevData.findIndex(
  //       (prevRow) => prevRow['startTs'] === row['Start time']
  //     );
  //     if (index === -1) {
  //       return prevData;
  //     } else {
  //       const newData = [...prevData];
  //       newData.splice(index, 1);
  //       return newData;
  //     }
  //   });
  // };

  const handleRefreshTable = () => {
    setRefreshTable(!refreshTable);
  };

  const fetchTableData = async () => {
    try {
      const currentData = await api.get(EVENTS_API, {
        headers: {
          Authorization: 'Bearer ' + cookies.get('admin-tokens').accessToken,
          'Content-Type': 'application/json'
        }
      });
      setFetchedData(currentData.data.sort((a: any, b: any) => b.id - a.id));
    } catch (error) {
      console.error(error);
    }
  };

  const formatDate = (dateString: any) => {
    const date = new Date(Number(dateString));
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };
  // =============================================================================
  // Styling
  const customStyles = {
    headRow: {
      style: {
        color: 'white',
        backgroundColor: '#6699CC' //blue
      }
    },
    headCells: {
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
        border: '1px solid #ddd', // add border to header cells
        justifyContent: 'center'
      }
    },
    rows: {
      style: {
        minHeight: '56px',
        '&:nth-child(even)': {
          backgroundColor: '#FFFFFF' //white
        },
        '&:nth-child(odd)': {
          backgroundColor: '#D0D5E8' //grey
        },
        border: '1px solid #ddd' // add border to rows
      }
    },
    cells: {
      style: {
        fontSize: '14px',
        paddingLeft: '8px',
        paddingRight: '8px',
        width: '20%',
        borderRight: '1px solid #ddd', // add right border to cells
        justifyContent: 'center'
      }
    }
  };

  // =============================================================================
  return (
    <CustomContainer>
      <div>
        <MyModal open={isAddModalOpen} onClose={handleCloseAddModal}>
          <>
            <AddEventTableModal
              handleCloseAddModal={handleCloseAddModal}
              handleRefreshTable={handleRefreshTable}
            />
          </>
        </MyModal>
        <MyModal open={isEditModalOpen} onClose={handleCloseEditModal}>
          <>
            <EditEventTableModal
              handleCloseEditModal={handleCloseEditModal}
              rowData={editRow}
              handleRefreshTable={handleRefreshTable}
            />
          </>
        </MyModal>

        <div
          style={{
            marginTop: 10,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Button
            type='button'
            variant='primary'
            color='dark'
            onClick={handleAddEvent}
          >
            Add Event
          </Button>
        </div>
        <DataTable
          title='Event Log'
          columns={columns}
          data={fetchedData}
          defaultSortFieldId={1}
          progressPending={loading}
          pagination
          customStyles={customStyles}
        />
      </div>
    </CustomContainer>
  );
}

export default EventsLog;
