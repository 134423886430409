import { useCallback, useEffect, useState } from 'react';
import { omit } from 'lodash';
import { DataGrid, GridColDef, GridRowModel } from '@mui/x-data-grid';
import { WorkSheet, utils } from 'xlsx';

export type Row = any[];
export type RowCol = { rows: Row[]; columns: GridColDef[] };

export interface ControlledDataGridProps {
  worksheet: WorkSheet;
  sheetIndex: number;
  updateWorksheetJson: (index: number, ws: WorkSheet) => void;
}

export const ControlledDataGrid = ({
  worksheet,
  updateWorksheetJson,
  sheetIndex
}: ControlledDataGridProps) => {
  const [rows, setRows] = useState<Row[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);

  function getColumnName(
    header: string,
    layman: string | undefined,
    unit: string | undefined
  ) {
    let columnName = header;
    if (layman) columnName += ` (${layman})`;
    if (unit && unit !== 'NA' && unit !== 'UNITS') columnName += ` (${unit})`;
    return columnName;
  }

  function worksheetToMUIDataGrid(ws: WorkSheet): RowCol {
    /* create an array of arrays */
    const rows: Row[] = utils
      .sheet_to_json(ws, { header: 1 })
      .filter((_, index) => index > 0)
      .map((row: any, index) => ({
        ...row,
        id: index
      }));

    const laymanColumns = rows.shift() || ({} as any);
    const units = rows.shift() || ({} as any);

    const columns = Object.values(omit(rows.shift(), 'id') as any).map(
      (header: any, index) => ({
        field: String(index), // MUIDG will access row["0"], row["1"], etc
        headerName: getColumnName(header, laymanColumns[index], units[index]),
        editable: true // enable cell editing
      })
    );

    return { rows, columns }; // these can be fed to setRows / setColumns
  }

  function muiDataGridToWorksheet(rows: Row[]): WorkSheet {
    function arrayify(rows: any[]): Row[] {
      return rows.map((row) => {
        var length = Object.keys(row).length;
        for (; length > 0; --length) if (row[length - 1] != null) break;
        return Array.from({ length, ...row });
      });
    }
    return utils.aoa_to_sheet(arrayify(rows));
  }

  const processRowUpdate = useCallback(
    (rowNew: GridRowModel, _: GridRowModel) => {
      const newRows = rows.map((row: GridRowModel) =>
        row.id === rowNew.id ? rowNew : row
      );
      setRows(newRows as Row[]);
      updateWorksheetJson(sheetIndex, muiDataGridToWorksheet(newRows as Row[]));
      return rowNew;
    },
    [columns, rows]
  );

  useEffect(() => {
    const convertedData = worksheetToMUIDataGrid(worksheet);
    setRows(convertedData.rows);
    setColumns(convertedData.columns);
  }, [worksheet]);

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      processRowUpdate={processRowUpdate}
    />
  );
};
