import _ from 'lodash';
import momentTZ from 'moment-timezone';

const processBoxData = (inp: any, binSize: string = '1D') => {
  let format = 'MM/D/YYYY';

  if (binSize === '1W') {
    format = 'YYYY[W]WW';
  } else if (binSize === '1M') {
    format = 'YYYYMM';
  }

  const results = inp.data.map((datum: any, index: any) => {
    const groupedList: any = _.groupBy(datum, (d) =>
      momentTZ(d.x).format(format)
    );

    return {
      labels: Object.keys(groupedList).map((l: any) =>
        momentTZ(l).format('DD MMM YYYY')
      ),
      data: Object.keys(groupedList).map((d: string) =>
        groupedList[d].map((i: any) => Number(i.y.toFixed(inp.accuracy[index])))
      )
    };
  });

  return results;
};

// const processDailyAvgData = (data: any) => {
//   return processBoxData(data).map((datum: any) =>
//     datum.map((d: any) => ({
//       x: d.x,
//       y: _.mean(d.y),
//       label: `${momentTZ(d.x).format('DD MMM')}\n${_.mean(d.y).toFixed(
//         2
//       )}`
//     }))
//   );
// };

const processScatterData = (data: any) => {
  if (data && data.data.length !== 2) return [];
  const groupLists = _.map(processBoxData(data), 'data');
  const scatterData: any[] = [];

  groupLists[0].map((d: any, index: any) => {
    if (!!groupLists[1][index]) {
      const x = _.mean(groupLists[0][index]);
      const y = _.mean(groupLists[1][index]);
      scatterData.push({
        x,
        y,
        label: `x: ${x.toFixed(2)}\ny: ${y.toFixed(2)}`
      });
    }
  });

  return scatterData;
};

const processAvgData = (data: any, accuracy: any) => {
  const maxPoints = 100;
  const results = data.map((datum: any, index: any) => {
    datum.sort((d1: any, d2: any) => d1.x - d2.x);
    const startTime = datum[0].x;
    const endTime = datum[datum.length - 1].x;
    const fullWindow = endTime - startTime;
    const binSize = Math.floor(fullWindow / Math.min(maxPoints, datum.length));
    if (datum.length > maxPoints) {
      const newFiltered: any[] = [];
      let i = 0;

      while (i < datum.length) {
        let sum = 0.0,
          j = i;
        const startPivot = datum[i].x;
        const endPivot = startPivot + binSize;

        while (j < datum.length && datum[j].x <= endPivot) {
          sum += datum[j++].y;
        }

        newFiltered.push({
          x: startPivot + binSize / 2,
          y: sum / (j - i),
          tooltip: {
            label: `${momentTZ(startPivot + binSize / 2).format(
              'DD MMM YYYY, HH:mm'
            )}`,
            value: `${(sum / (j - i)).toFixed(accuracy[index])}`
          }
        });
        i = j;
      }

      datum = newFiltered;
    }

    let finalData = [];

    for (let i = 0; i < datum.length; i++) {
      finalData.push(datum[i]);
      if (i < datum.length - 1 && datum[i + 1].x - datum[i].x > binSize * 3) {
        finalData.push({
          x: new Date(datum[i].x + binSize),
          y: null
        });
      }
    }

    return finalData;
  });

  return results;
};
const getZoomedData = (
  props: any,
  zoomedDomain: any,
  debounce: boolean,
  isFirst: boolean,
  setFirst: any
) => {
  const maxPoints = 100;
  const results = props.data.map((datum: any, index: any) => {
    datum.sort((d1: any, d2: any) => d1.x - d2.x);
    let filtered = datum.filter(
      (d: any) => d.x >= zoomedDomain[0] && d.x <= zoomedDomain[1]
    );

    const fullWindow = zoomedDomain[1].getTime() - zoomedDomain[0].getTime();
    const binSize = Math.floor(
      fullWindow / Math.min(maxPoints, filtered.length)
    );

    if (debounce || isFirst) {
      if (filtered.length > maxPoints) {
        const newFiltered: any[] = [];
        let i = 0;

        while (i < filtered.length) {
          let sum = 0.0,
            j = i;
          const startTime = filtered[i].x.getTime();
          const endTime = startTime + binSize;

          while (j < filtered.length && filtered[j].x.getTime() <= endTime) {
            sum += filtered[j++].y;
          }

          newFiltered.push({
            x: momentTZ(startTime + binSize / 2),
            y: sum / (j - i),
            qcFlag: filtered[i].qcFlag,
            tooltip: {
              label: `${momentTZ(new Date(startTime + binSize / 2)).format(
                'DD MMM YYYY, HH:mm'
              )}`,
              value: `${(sum / (j - i)).toFixed(props.accuracy[index])}`
            }
          });
          i = j;
        }

        filtered = newFiltered;
      }
    }

    let finalFiltered = [];

    for (let i = 0; i < filtered.length; i++) {
      finalFiltered.push(filtered[i]);
      if (
        i < filtered.length - 1 &&
        filtered[i + 1].x - filtered[i].x > binSize * 3
      ) {
        finalFiltered.push({
          x: filtered[i].x.add(binSize, 'ms'),
          y: null
        });
      }
    }

    return finalFiltered;
  });

  if (isFirst) {
    setFirst(false);
  }

  return results;
};

const generate5MinIntervals = (start: number, end: number) => {
  const res: number[] = [];

  while (start < end) {
    res.push(start);
    start = start + 5 * 60 * 1000;
  }

  return res;
};

export {
  getZoomedData,
  processAvgData,
  processBoxData,
  processScatterData,
  generate5MinIntervals
};
