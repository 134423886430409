import { useState, useEffect } from 'react';
import Container from 'components/common/Container';
import { Snackbar, Alert, Button, Typography } from '@mui/material';
import TextInput from 'components/common/TextInput';
import TextField from '@mui/material/TextField';
import Dropdown from 'components/common/Dropdown';
import { MenuItem } from '@mui/material';
import api from 'utils/api';
import Cookies from 'universal-cookie';

const modalContainerStyle = {
  marginBottom: 48,
  marginTop: 48,
  border: '2px solid black',
  borderRadius: '20px',
  backgroundColor: 'white'
};

const formatDate = (dateString: any) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export default function ReviewModal(props: any) {
  const { data, actionType } = props;
  const [eventId, setEventId]: [any, any] = useState(null);
  const [comment, setComment]: [any, any] = useState(null);
  const cookies = new Cookies();

  const handleEvent = (id: any) => {
    if (id === 'none') {
      setEventId(null);
    } else {
      setEventId(id);
    }
  };

  const handleReview = async () => {
    try {
      await api.post(
        '/v1/mesn/alerts' + '/' + data.id,
        {
          ...(eventId && { eventId }),
          ...(comment && { comment })
        },
        {
          headers: {
            Authorization:
              'Bearer ' + cookies.get('admin-tokens').data.accessToken,
            'Content-Type': 'application/json'
          }
        }
      );
      props.handleRefresh();
      handleClose();
    } catch (err: any) {
      alert('Server error, please try again later!');
    }
  };

  const handleComment = (e: any) => {
    setComment(e.target.value);
  };
  const handleClose = () => {
    props.handleClose();
  };

  return (
    <Container style={modalContainerStyle}>
      <div style={{ height: 40 }} />
      <Typography variant='h2' color='blue.900' align='center'>
        View/Edit Alert
      </Typography>
      <div
        style={{
          margin: '40px 0',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <TextInput
          variant='outlined'
          id='id'
          name='id'
          label='ID'
          disabled={true}
          value={data.id}
        />
        <TextInput
          variant='outlined'
          id='createdOn'
          name='createdOn'
          label='Created On'
          disabled={true}
          value={formatDate(data.createdOn)}
        />
        <TextInput
          variant='outlined'
          id='location'
          name='location'
          label='Location'
          disabled={true}
          value={data.location}
        />
        <TextInput
          variant='outlined'
          id='sensor'
          name='sensor'
          label='Sensor'
          disabled={true}
          value={data.sensorClass}
        />
        <TextInput
          variant='outlined'
          id='parameter'
          name='parameter'
          label='Parameter'
          disabled={true}
          value={data.parameterName}
        />
        <TextInput
          variant='outlined'
          id='value'
          name='value'
          label='Value'
          disabled={true}
          value={data.value}
        />
        <TextInput
          variant='outlined'
          id='unit'
          name='unit'
          label='Unit'
          disabled={true}
          value={data.unit}
        />
        <TextInput
          variant='outlined'
          id='desc'
          name='desc'
          label='Alert description'
          disabled={true}
          value={data.description}
        />
        {data.eventName ? (
          <TextInput
            variant='outlined'
            id='event'
            name='event'
            label='Event'
            disabled={true}
            value={data.eventName}
          />
        ) : actionType == 'EDIT' ? (
          <Dropdown
            title='Event'
            helperText='Select an associated event(optional)'
            handleSelect={handleEvent}
          >
            <MenuItem value='none'>
              <em>None</em>
            </MenuItem>
            {props.events.map((e: any) => {
              return (
                <MenuItem value={e.id} title={e.name}>
                  {e.name}
                </MenuItem>
              );
            })}
          </Dropdown>
        ) : null}
        {data.reviewedByName && (
          <TextInput
            variant='outlined'
            id='reviewedBy'
            name='reviewedBy'
            label='Reviewed by'
            disabled={true}
            value={data.reviewedByName}
          />
        )}
        {data.reviewedOn && (
          <TextInput
            variant='outlined'
            id='reviewedOn'
            name='reviewedOn'
            label='Reviewed on'
            disabled={true}
            value={() => {
              if (data.reviewedOn) {
                return formatDate(data.reviewedOn);
              }
              return '';
            }}
          />
        )}
        <TextField
          variant='outlined'
          id='comments'
          name='comments'
          label='Additional Comments'
          placeholder='Optional'
          value={data.comment}
          onChange={handleComment}
          multiline
          rows={6}
          disabled={actionType == 'EDIT' ? false : true}
        />
        <div style={{ height: 32 }} />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type='button'
            variant='secondary'
            color='dark'
            onClick={handleClose}
            style={{ marginRight: 10 }}
          >
            Close
          </Button>
          {actionType == 'EDIT' && (
            <Button
              type='button'
              variant='primary'
              color='dark'
              onClick={handleReview}
            >
              Save Changes
            </Button>
          )}
        </div>
      </div>
    </Container>
  );
}
