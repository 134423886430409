interface props {
  data: any;
}

export default function OnBoardingView({ data }: props) {
  return (
    <div className='view-wrapper'>
      <h1>Overview</h1>
      <div className='row'>
        <div>Owner ID</div>
        <div>{data.ownerId}</div>
      </div>
      <br />

      <h1>Sensor Classes</h1>
      {data.metadataDeclaration.sensorClasses.map((sensor: any, i: number) => (
        <div key={`sensor-${i}`}>
          <h2>{sensor.className}</h2>
          <h3>Overview</h3>
          <div className='table'>
            <div className='row'>
              <div>Product name</div>
              <div>{sensor.productName}</div>
            </div>
            <div className='row'>
              <div>Manufacturer</div>
              <div>{sensor.manufacturer}</div>
            </div>
            <div className='row'>
              <div>Sampling min interval</div>
              <div>{sensor?.sampling?.minInterval}</div>
            </div>
            <div className='row'>
              <div>Sampling max interval</div>
              <div>{sensor?.sampling?.maxInterval}</div>
            </div>
            <div className='row'>
              <div>Sampling burst supported</div>
              <div>{sensor?.sampling?.burstSupported}</div>
            </div>
            <div className='row'>
              <div>Sampling min burst duration</div>
              <div>{sensor?.sampling?.minBurstDuration}</div>
            </div>
            <div className='row'>
              <div>Sampling max burst duration</div>
              <div>{sensor?.sampling?.maxBurstDuration}</div>
            </div>
            <div className='row'>
              <div>Sampling min burst interval</div>
              <div>{sensor?.sampling?.minBurstInterval}</div>
            </div>
            <div className='row'>
              <div>Sampling max burst interval</div>
              <div>{sensor?.sampling?.maxBurstInterval}</div>
            </div>
            <div className='row'>
              <div>Configuration schema</div>
              <div>{sensor.configurationSchema}</div>
            </div>
            <div className='row'>
              <div>Calibration schema</div>
              <div>{sensor.calibrationSchema}</div>
            </div>
          </div>
          <br />

          {sensor.quantityDescriptors && (
            <>
              <h3>Quantities</h3>
              {sensor.quantityDescriptors.map((qty: any, i: number) => (
                <div key={i}>
                  <h4>{qty.name}</h4>
                  <div className='table'>
                    <div className='row'>
                      <div>Name</div>
                      <div>{qty.name}</div>
                    </div>
                    <div className='row'>
                      <div>Display name</div>
                      <div>{qty.displayName}</div>
                    </div>
                    <div className='row'>
                      <div>Units</div>
                      <div>{qty.units}</div>
                    </div>
                    <div className='row'>
                      <div>Minimum</div>
                      <div>{qty.minimum}</div>
                    </div>
                    <div className='row'>
                      <div>Maximum</div>
                      <div>{qty.maximum}</div>
                    </div>
                    <div className='row'>
                      <div>Tolerance/accuracyn</div>
                      <div>{qty.tolerance}</div>
                    </div>
                    <div className='row'>
                      <div>Repeatibility</div>
                      <div>{qty.repeatability}</div>
                    </div>
                    <div className='row'>
                      <div>Drift (per day)</div>
                      <div>{qty.drift}</div>
                    </div>
                    <div className='row'>
                      <div>isEngineering</div>
                      <div>{qty.isEngineering}</div>
                    </div>
                  </div>
                </div>
              ))}
              <br />
            </>
          )}

          {sensor.blobDescriptors && (
            <>
              <h3>Blob quantities</h3>
              <div className='table five-col'>
                <div className='row'>
                  <div>Name</div>
                  <div>Display name</div>
                  <div>Description</div>
                  <div>MIME types</div>
                  <div>Docs</div>
                </div>
                {sensor.blobDescriptors.map((blb: any, i: number) => (
                  <div className='row' key={`blb-${i}`}>
                    <div>{blb.name}</div>
                    <div>{blb.displayName}</div>
                    <div>{blb.description}</div>
                    <div>{blb.mimeTypes}</div>
                    <div>
                      <a href={blb.docs} target='_blank'>
                        Document
                      </a>
                    </div>
                  </div>
                ))}
              </div>
              <br />
            </>
          )}

          <h3>Power consumption</h3>
          <div className='table two-col'>
            <div className='row'>
              <div>Mode</div>
              <div>Power (W)</div>
            </div>
            <div className='row'>
              <div>Active</div>
              <div>{sensor?.powerConsumption?.active}</div>
            </div>
            <div className='row'>
              <div>Idle</div>
              <div>{sensor?.powerConsumption?.idle}</div>
            </div>
            <div className='row'>
              <div>Sleep</div>
              <div>{sensor?.powerConsumption?.sleep}</div>
            </div>
          </div>
          <br />

          <div className='table'>
            <div className='row'>
              <div>Power priority</div>
              <div>{sensor.powerPriority}</div>
            </div>
            <div className='row'>
              <div>Data priority</div>
              <div>{sensor.dataPriority}</div>
            </div>
          </div>
          <br />
        </div>
      ))}
    </div>
  );
}
