import FileSaver from 'file-saver';
import momentTZ from 'moment-timezone';

export const exportRawData = (fileName: string, data: any) => {
  const headers = [
    'ID',
    'Interval',
    'DataTs',
    'Date',
    'Value',
    'QcFlag',
    'Unit'
  ];
  const rows = [
    headers,
    ...data.dataTs.map((dataTs: any, index: any) => [
      data.dataId[index],
      data.interval[index],
      dataTs,
      momentTZ(Number(dataTs.split('#')[1])).toISOString(true),
      data.qcValue[index],
      data.qcFlag[index],
      data.unit[index]
    ])
  ];

  let csvContent = new File(
    [rows.map((e) => e.join(',')).join('\r\n')],
    fileName + '.csv',
    {
      type: 'text/csv;charset=utf-8'
    }
  );
  FileSaver.saveAs(csvContent);
};
