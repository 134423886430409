import { useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import Cookies from 'universal-cookie';

import api from 'utils/api';
import ParametersTable from 'components/tables/ParametersTable';
import ParamDetailsModal from './ParamDetailsModal';
import { isTokenAvailable } from 'pages/Login/state';

export default function Users() {
  const cookies = new Cookies();
  const [email, setEmail] = useState('');
  const [param, setParam]: [any, any] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const setTokenState = useSetRecoilState(isTokenAvailable);
  const navigate = useNavigate();

  const handleParam = (param: any) => {
    setParam(param);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setParam(null);
  };

  const handleParamUpdate = async (data: any) => {
    if (data.isFree === param.isFree) {
      handleCloseModal();
      return;
    }
    try {
      const res = await api.patch(`/admin/parameters/${data.id}`, {
        isFree: data.isFree
      });
      setParam(null);
      alert('Saved successfully');
    } catch (error: any) {
      alert(error.message);
    }
  };

  //   const handleSaveUser = async () => {
  //     if (!user) {
  //       alert('Please enter Email and fetch user details');
  //       return;
  //     }
  //     try {
  //       const res = await api.patch(`/admin/users/${user.id}`, user);
  //       setUser(null);
  //       alert('Saved successfully');
  //     } catch (error: any) {
  //       alert(error.message);
  //     }
  //   };

  return (
    <>
      <ParametersTable setParam={handleParam} />
      <ParamDetailsModal
        open={modalOpen}
        onClose={handleCloseModal}
        param={param}
        setParam={setParam}
        handleParamUpdate={handleParamUpdate}
      />
    </>
  );
}
