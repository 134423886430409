import useWidth from 'utils/useWidth';

// =============================================================================
interface ContainerProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export default function Container(props: ContainerProps) {
  const width = useWidth();

  return (
    <div
      style={{
        marginLeft: getMarginSize(width),
        marginRight: getMarginSize(width),
        ...props.style
      }}
    >
      {props.children}
    </div>
  );

  function getMarginSize(width: string) {
    switch (width) {
      case 'mobile':
        return 32;
      case 'tablet':
        return 50;
      case 'laptop':
        return 150;
      case 'destop':
        return 250;
      default:
        return 150;
    }
  }
}
