import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { errorMessage } from 'pages/Login/state';
import { useRecoilState } from 'recoil';

export default function ErrorSnackbar() {
  const [snackbarMessage, setSnackbarMessage] = useRecoilState(errorMessage);

  return (
    <Snackbar
      open={!!snackbarMessage}
      autoHideDuration={3000}
      onClose={() => setSnackbarMessage('')}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        sx={{
          width: '100%',
          maxWidth: 300
        }}
        onClose={() => setSnackbarMessage('')}
        variant={'filled'}
        severity={'error'}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
}
