import { atom } from 'recoil';
import moment from 'moment';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import {
  WATER_PROPERTIES,
  WATER_CONTENTS,
  AIR_PROPERTIES,
  MONTHLY_CRUISE_PARAMETERS,
  WAVE_AND_TIDE,
  HYDRODYNAMICS
} from 'utils/params';

export const startAtom = atom({
  key: 'start',
  default: moment().subtract(2, 'weeks').startOf('day').unix() * 1000
});

export const endAtom = atom({
  key: 'end',
  default: moment().endOf('day').unix() * 1000
});

export const startQcAtom = atom({
  key: 'startQc',
  default: {
    i: null,
    ts: null
  }
});

export const endQcAtom = atom({
  key: 'endQc',
  default: {
    i: null,
    ts: null
  }
});

export const activeGraphAtom = atom({
  key: 'activeGraph',
  default: 'Line Chart'
});

export const isLoadingAtom = atom({
  key: 'isLoading',
  default: false
});

export const graphDataAtom = atom({
  key: 'graphData',
  default: []
});

export const cardSetAtom = atom({
  key: 'cardSet',
  default: []
});

export const paramsAtom = atom({
  key: 'params',
  default: {}
});

export const useSetParams = () => {
  const setParams = useSetRecoilState(paramsAtom);
  return (data: any) => {
    setParams({ data: data });
  };
};

export const useAddCard = () => {
  const [cardSet, setCardSet]: [any, any] = useRecoilState(cardSetAtom);
  return (data: any) => {
    setCardSet([...cardSet, data]);
  };
};

export const useDeleteCard = () => {
  const [cardSet, setCardSet]: [any, any] = useRecoilState(cardSetAtom);
  return (key: any) => {
    const newCardSet = [...cardSet];
    newCardSet.splice(key, 1);
    setCardSet(newCardSet);
  };
};

export const useUpdateCard = () => {
  const [cardSet, setCardSet]: [any, any] = useRecoilState(cardSetAtom);
  return (index: any, data: any) => {
    const newCardSet = [...cardSet];
    newCardSet[index] = {
      ...newCardSet[index],
      ...data
    };

    setCardSet(newCardSet);
  };
};

export const useUpdateCardLocation = () => {
  const [cardSet, setCardSet]: [any, any] = useRecoilState(cardSetAtom);
  return (index: any, data: any) => {
    const newCardSet = [...cardSet];
    newCardSet[index].location = data;

    setCardSet(newCardSet);
  };
};

export const useUpdateCardDepth = () => {
  const [cardSet, setCardSet]: [any, any] = useRecoilState(cardSetAtom);
  return (index: any, data: any) => {
    const newCardSet = [...cardSet];
    newCardSet[index] = { ...newCardSet[index], depth: data };

    setCardSet(newCardSet);
  };
};

export const useDeleteDataset = () => {
  const [graphData, setGraphData] = useRecoilState(graphDataAtom);
  return (index: any) => {
    const newGraphData = [...graphData];
    newGraphData.splice(index, 1);
    setGraphData(newGraphData);
  };
};
