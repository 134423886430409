import React from 'react';
import Typography from '@mui/material/Typography';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import PopoverOptions from './PopoverOptions';
import useViewportRatio from 'utils/useViewportRatio';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import custom_palettes from 'theme/custom_palettes';
import custom_typographys from 'theme/custom_typographys';
import {
  useDeleteDataset,
  useDeleteCard,
  useUpdateCardLocation,
  useUpdateCardDepth
} from 'pages/Qc/state';

// =============================================================================
const POPOVER_POSITION_FROM_TOP = 500;
const POPOVER_POSITION_FROM_LEFT = 400;

const LOCATION_OPTIONS = [
  'St. John’s Island'
  // "Raffles Lighthouse",
  // "Pulau Ubin",
];

const CRUISE_LOCATION_OPTIONS = [
  'St. John’s Island',
  'Raffles Lighthouse',
  'Pulau Ubin',
  'Hantu',
  'Kusu',
  'West Ubin'
];

const DEPTH_OPTIONS = ['Shallow'];

const MULTIPLE_DEPTH_OPTIONS = ['Shallow', 'Mid water column', 'Near seabed'];

const PARAMETER_OPTIONS = [
  'Water Temperature',
  // "Salinity",
  'Dissolved Oxygen',
  'pH',
  'Chlorophyll',
  'Phycoerythrin'
];

// =============================================================================
const set_card_wrapper_style: any = [
  { border: `2px solid ${custom_palettes.yellow[400]}` },
  { border: `2px solid ${custom_palettes.green[500]}` }
];

const title_wrapper_style: any = [
  { backgroundColor: custom_palettes.yellow[50] },
  { backgroundColor: custom_palettes.green[50] }
];

const title_style: any = [
  { color: custom_palettes.yellow[400] },
  { color: custom_palettes.green[500] }
];

const custom_select_wrapper_style = {
  width: '90%',
  margin: '0 auto',
  // border: `1px solid ${custom_palettes.gray[100]}`,
  borderRadius: '24px',
  marginBottom: '12px',
  ...custom_typographys.body_regular,
  color: custom_palettes.gray[800],
  padding: '0 16px'
};

// =============================================================================
interface SetCardProps {
  index: string;
  location: string;
  depth: string;
  parameter: string;
  isCruiseParam: boolean;
  isDcps: boolean;
}

export default function SetCard(props: SetCardProps) {
  return (
    <div
      style={{
        width: '100%',
        borderRadius: '16px',
        margin: '8px auto',
        maxWidth: '90%',
        ...set_card_wrapper_style[props.index]
      }}
    >
      <Header index={props.index} />
      <CustomForm
        index={props.index}
        location={props.location}
        depth={props.depth}
        parameter={props.parameter}
        isCruiseParam={props.isCruiseParam}
        isDcps={props.isDcps}
      />
    </div>
  );
}

// =============================================================================
interface HeaderProps {
  index: string;
}

const Header = (props: HeaderProps) => {
  const deleteDataset = useDeleteDataset();
  const deleteCard = useDeleteCard();
  return (
    <div
      style={{
        height: 40,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '16px',
        borderRadius: '16px 16px 0px 0px',
        marginBottom: '8px',
        ...title_wrapper_style[props.index]
      }}
    >
      <Typography
        variant='body_regular_strong'
        style={title_style[props.index]}
      >
        SET {parseInt(props.index) + 1}
      </Typography>
      <div style={{ flex: 1 }} />
      <IconButton onClick={deleteCardOnClick} style={{ marginRight: 8 }}>
        <CloseOutlinedIcon />
      </IconButton>
    </div>
  );

  function deleteCardOnClick() {
    deleteDataset(props.index);
    deleteCard(props.index);
  }
};

// =============================================================================
interface CustomFormProps {
  index: string;
  location: string;
  depth: string;
  parameter: string;
  isCruiseParam: boolean;
  isDcps: boolean;
}

const CustomForm = (props: CustomFormProps) => {
  const updateCardLocation = useUpdateCardLocation();
  const updateCardDepth = useUpdateCardDepth();

  const handleLocationChange = (e: any) => {
    updateCardLocation(props.index, e.target.value);
  };

  const handleDepthChange = (e: any) => {
    updateCardDepth(props.index, e.target.value);
  };
  return (
    <div>
      {/* <CustomSelect
        defaultValue='St. John’s Island'
        value={props.location}
        menu={props.isCruiseParam ? CRUISE_LOCATION_OPTIONS : LOCATION_OPTIONS}
        onChange={handleLocationChange}
      /> */}
      <CustomParameterSelect
        index={props.index}
        value={props.location}
        location={props.location}
      />

      <CustomSelect
        defaultValue='Shallow'
        value={props.depth}
        menu={
          props.isCruiseParam || props.isDcps
            ? MULTIPLE_DEPTH_OPTIONS
            : DEPTH_OPTIONS
        }
        onChange={handleDepthChange}
      />

      <CustomParameterSelect
        index={props.index}
        value={props.parameter}
        location={props.location}
      />
    </div>
  );

  // function handleParameterChange(event: any) {
  //   console.log(event.target.value)
  // }
};

// =============================================================================
interface CustomSelectProps {
  defaultValue: string;
  value: string;
  menu: string[];
  onChange?: (event: SelectChangeEvent) => void;
}

const CustomSelect = (props: CustomSelectProps) => {
  return (
    <FormControl fullWidth>
      <Select
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        value={props.value}
        sx={{
          ...custom_select_wrapper_style,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: custom_palettes.gray[100]
          }
        }}
        IconComponent={() => <KeyboardArrowDownSharpIcon />}
      >
        {props.menu.map((item: string, index: number) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

// =============================================================================
const parameter_select_wrapper_style = {
  display: 'flex',
  width: '90%',
  margin: 'auto',
  height: 50,
  border: `1px solid ${custom_palettes.gray[100]}`,
  borderRadius: 24,
  alignItems: 'center',
  marginBottom: 12
};

const CustomParameterSelect = (props: any) => {
  const [SelectedCardIndex, setSelectedCardIndex] = React.useState<any>(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const ratio = useViewportRatio(1440);

  return (
    <>
      <PopoverOptions
        anchorEl={anchorEl}
        onClose={handleClose}
        setAnchorEl={setAnchorEl}
        anchorPosition={{
          top: POPOVER_POSITION_FROM_TOP,
          left: POPOVER_POSITION_FROM_LEFT * ratio
        }}
        mode='updateCard'
        cardIndex={SelectedCardIndex}
        location={props.location}
      />
      <div style={parameter_select_wrapper_style} onClick={handleClick}>
        <Typography
          variant='body_regular'
          color='blue.900'
          style={{
            marginLeft: 32,
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {props.value}
        </Typography>
        <div style={{ flex: 1 }} />
        <KeyboardArrowDownSharpIcon
          style={{ marginRight: 16, color: custom_palettes.gray[800] }}
        />
      </div>
    </>
  );

  function handleClick(event: any) {
    setSelectedCardIndex(props.index);
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
};
