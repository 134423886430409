import { useSetRecoilState } from 'recoil';

import Button from '@mui/material/Button';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
export default function UploadFile(props: any) {
  return (
    <Button
      variant='secondary'
      color='dark'
      aria-label='upload zip'
      component='label'
      startIcon={<DriveFolderUploadIcon />}
      style={{ width: 200 }}
    >
      <input
        hidden
        accept={props.accept}
        type='file'
        onChange={(e) => {
          props.handleChange(e);
        }}
        onClick={(e: any) => {
          e.target.value = null;
        }}
      />
      {props.title ? props.title : 'Upload'}
    </Button>
  );
}
