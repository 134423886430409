import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import CustomContainer from 'components/CustomContainer';
import Form from 'pages/Login/Form';
import ErrorSnackbar from 'pages/Login/ErrorSnackbar';

export default function Login() {
  return (
    <Container
      maxWidth='tablet'
      style={{
        height: window.innerHeight,
        display: 'grid',
        alignItems: 'center'
      }}
    >
      <CustomContainer>
        <Typography variant='h2' color='blue.900' sx={{ textAlign: 'center' }}>
          Login
        </Typography>
        <Form />
        <ErrorSnackbar />
      </CustomContainer>
    </Container>
  );
}
